/* #Navigation
================================================== */
.start-header {
	opacity: 1;
	transform: translateY(0);
	transition : all 0.3s ease-out;
    &.scroll-on {
        position: fixed;
        box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
        padding: 10px 0;
        transition : all 0.3s ease-out;
        @include setMinRwd{
            .navbar-brand{
                img{
                    transition : all 0.3s ease-out;
                }
            }
        }
    }
    .container{
        max-width: 1650px;
        img{
            margin: 0 !important;
        }
    }
}

.navigation-wrap{
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1050;
	transition : all 0.3s ease-out;
    @include setMaxRwd{
        padding: 5px 0;
    }
}

.navbar{
    @include fontSize(16px);
	padding: 0;

    @include setWidthRwd(1550px,1700px){
        .navbar-brand {
            max-width: 13em;
            margin-left: 0;
        }
    }

    @include setWidthRwd(1050px,1500px){
        .nav-item {
            position: static;
            margin-left: 0.5em !important;
        }
    }

    @include setWidthRwd(1070px,1300px){
        flex-direction: column;
        padding-top: 0.5em;
    }

    @include setWidthRwd(1000px,1020px){
        align-items: flex-start;
    }

    @include pad1080{
        justify-content: space-between;
    }

    // LOGO
    &-brand{
        padding: 0;
        margin: 0;
        img{
            display: block;
            transition : all 0.3s ease-out;
        }
        
        @include setStyleMinRwd(1400px){
            margin-left: get-vw(125px);
        }

        @include pad1080{
            max-width: get-vw-mobile(400px);
        }
    }

    // 
    &-light{
        .navbar-toggler-icon {
            width: 25px;
            height: 17px;
            background-image: none;
            position: relative;
            border-bottom: 1px solid #000;
            transition: all 300ms linear;
            &::after, 
            &::before{
                width: 25px;
                position: absolute;
                height: 1px;
                background-color: #000;
                top: 0;
                left: 0;
                content: '';
                z-index: 2;
                transition: all 300ms linear;
            }
            &::after{
                top: 8px;
            }
        }

        .navbar-nav{
            .nav-link {
                display: inline-flex;
            }
        }
    }

    // 主連結的外框
    &-collapse{
        flex-wrap: wrap;
        justify-content: space-between;

        @include setMinRwd{
            padding-top: 25px;
            padding-bottom: 15px;
        }

        @include setWidthRwd(800px,1500px){
            width: 100%;
            margin-top: 0;
            flex-wrap: nowrap;
        }

        @include setWidthRwd(1070px,1300px){
            padding-top: 0;
            padding-bottom: 0;
        }

        @include pad1080{
            position: fixed;
            @include setSize(100%,100vh);
            top: 0;
            left: -100%;
            background: #fff;
            transition: 0.15s;
            overflow-y: auto;
            max-width: 60vw;
            &,
            &>ul{
                z-index: 100;
            }

            &>ul{
                position: relative;
                justify-content: flex-start !important;
            }

            &.show{
                display: block !important;
                left: 0;
            }
        }
    }

    &-overlay{
        display: block;
        @include setSize(100%,100vh);
        background-color: rgba(0,0,0,0.75);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        opacity: 0;
        visibility: hidden;

        &.active{
            opacity: 1;
            visibility: visible;
        }

        @include setMinRwd{
            display: block;
        }
    }

    // 手機用的漢堡
    &-toggler {
        float: right;
        border: none;
        padding-right: 0;
        &:active,
        &:focus {
            outline: none;
        }

        @include pad1080{
            display: block !important;
        }

        .hamburger-toggle{
            position: relative;
            display: inline-block;
            width: 50px;
            height: 50px;
            z-index: 11;
            .hamburger {
                position: absolute;
                transform: translate(-50%, -50%) rotate(0deg);
                left: 50%;
                top: 50%;
                width: 50%;
                height: 50%;
                pointer-events: none;
                span {
                    width: 100%;
                    height: 4px;
                    position: absolute;
                    background: $colorBlack;
                    border-radius: 2px;
                    z-index: 1;
                    transition: transform 0.2s cubic-bezier(0.77,0.2,0.05,1.0), background 0.2s cubic-bezier(0.77,0.2,0.05,1.0), all 0.2s ease-in-out;
                    left: 0px;
                    &:first-child{
                        top: 10%;
                        transform-origin: 50% 50%;
                        transform: translate(0% -50%) !important;
                    }
                    &:nth-child(2){
                        top: 50%;
                        transform: translate(0,-50%);
                    }
                    &:last-child{
                        left: 0;
                        top: auto;
                        bottom: 0;
                        transform-origin: 50% 50%;
                    }
                }

                &.active{
                    span {
                        position: absolute;
                        margin: 0;
                        &:first-child{
                            top: 45%;
                            transform: rotate(45deg);
                        }
                        &:nth-child(2){
                            left: 50%;
                            width: 0px;
                        }
                        &:last-child{
                            top: 45%;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }

        // 
        &.fixed{
            display: none !important;
            position: fixed;
            left: 59%;
            top: 2%;
            z-index: 1001;
            .hamburger-toggle{
                .hamburger{
                    span {
                        background-color: #fff;
                        &:first-child{
                            transform: rotate(40deg);
                        }

                        &:last-child{
                            transform: translateY(-18px) rotate(-40deg);
                        }
                    }
                }
            }
            
            &.active{
                display: block !important;
            }
            
        }
    }

    &-nav{
        // 主連結的link
        &.link{
            flex: 1;
            justify-content: space-between;

            .dropdown-menu{
                box-shadow: none;
                background: transparent;
                &-list{
                    li{
                        .dropdown-item{
                            @include fontSize(16px);
                            background-color: transparent !important;
                            white-space:normal;
                            &:hover{
                                color:$colorMain !important
                            }
                         }
    
                        .dropdown-menu-subContanier {
                            padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
                            .dropdown-item{
                                position: relative;
                               @include fontSize(14px);
                               color: #666;
                               padding-left: 1.25rem;
                               &::before{
                                content: '';
                                display: block;
                                @include setSize(10px,1px);
                                background-color: #696969;
                                position: absolute;
                                top: 12.5px;
                                left: 0;
                               }
                            }
    
                            @include setMaxRwd{
                                padding-top: 0;
                                margin-top: -2px;
                                padding-left: 20px;
                                max-height: 55vh;
                                overflow-x: hidden;
                                overflow-y: auto;
                                padding: 10px; 
    
                                position: static;
                                display: none;
                                opacity: 0;
                                visibility: hidden;
                                .dropdown-item{
                                    @include fontSize(15px);
                                    background-color: transparent !important;
                                    &:last-child{
                                        border-bottom: none;
                                    }
                                }
                                &.active{
                                    display: block;
                                    opacity: 1;
                                    visibility: visible; 
                                }
                            }
                        }
                    }
                }
            }

            @include setMinRwd{
                position: relative;
                flex: none;
                min-width: calc(740px - 2em);
                margin-left: auto;
                padding-right: 1em;
                
                .nav-link{
                    text-transform: uppercase;
                    flex-direction: column;
                }

                .nav-item{
                    &:hover{
                        .dropdown-menu{
                            display: block;
                            opacity: 1;
                            visibility: visible;
                            background: #fff;
                            max-height: 100vw;
                            left: 0; //-50%
                            // top: 4.4em;
                            padding: 0 !important;
                            -webkit-transform: translate3d(0, 0px, 0);
                            transform: translate3d(0, 0px, 0);
                            z-index: 1;
                            min-width: 100vw;
                            max-width: 100%;
                            &::before {
                                content: "";
                                display: block;
                                width: 1000%;
                                height: 100%;
                                background-color: #fff;
                                position: absolute;
                                left: -100%;
                                top: 14px;
                                z-index: -1;
                            }

                            &::after{
                                content: "";
                                width: 1000%;
                                height: 1px;
                                background-color: #eee;
                                display: block;
                                position: absolute;
                                top: 14px;
                                left: -100%;
                            }

                            .dropdown-menu-list{
                                width: 100%;
                                max-width: 1200px; //70em
                                padding: 10px 10px 0;
                                margin: 15px auto 0;
                                display: flex;
                                flex-wrap: wrap;
                                grid-column-gap: 15px;

                                position: relative;
                                justify-content: center;
                                left: calc(50vw - 50px); //50%
                                transform: translateX(calc(-100vw + 100px));

                            }
                        }
                    }
                }

                .dropdown-item{
                    &:hover{
                        background-color: transparent;
                        color: #000;
                    }
                }
            }

            @include setWidthRwd(1100px,1500px){
                min-width: calc(100% - 120px);
                margin-left: 0;
                padding-left: 1em;
                justify-content: space-around;
                .nav-item{
                    &:hover{
                        .dropdown-menu{
                            min-width: 100%;
                            top: 3.4em;
                            background-color: transparent;
                            .dropdown-menu-list{
                                left: calc(50% + 20px);
                                transform: translateX(calc(-50% + 20px));
                            }
                        }
                    }
                }
            }

            @include setWidthRwd(1000px,1100px){
                flex: 1;
                margin: auto;
                padding-right: 1em;
                justify-content: space-around;
            }

            @include pad1080{
                flex-direction: column;
                .nav-item{
                    padding-left: 1.5rem !important;;
                }
            }
        }

        //主連結的資訊
        &.info{
            @include pad1080{
                flex-wrap: wrap;
                &>li{
                    flex: 1;
                    
                    &.nav-item-social{
                        display: none;
                    }

                    &.nav-item-lang{
                        flex: 0 0 calc(100% - 3rem);
                        max-width: calc(100% - 3rem);
                        margin-top: 0.5rem;
                        order: 1;
                    }
                }
            }
        }
    }

    // 下拉
    &-mobile-icon{
        @include setSize(80px,40px);
        color: #000 !important;
        text-align: right;
        padding-right: 18px;
        position: absolute;
        right: 0;
        top: 5px;
        line-height: 40px;
        &::after {
            display: none;
        }

        &.show{
            .bi{
                transform: rotate(180deg);
            }
        }
    }
}

// 
.nav{
    &-link{
        position: relative;
        display: inline-block;
        color: $colorBase !important;
        @include fontSize(17px);
        font-weight: 500;
        padding: 5px 0 !important;
        transition: all 200ms linear;
        @include setMinRwd{
            line-height: 3;
        }

        @include pad1080{
            padding: 10px 0 !important;
        }

    }

    &-item{
        position: relative;
        transition: all 200ms linear;

        &.active,
        &:hover{
            .nav-link{
                color:$colorMain !important
            }
        }

        .icon {
            display: inline-block;
            vertical-align: top;
            flex: none;
            margin-top: -2px;

            .bi-caret-down-fill{
                font-size: 14px;
                color: $colorMain;
                display: inline-block;
                vertical-align: middle;
                margin-left: 5px;
                margin-top: -3px;
            }
        }

        // -reset在新版編輯器的 .container img { margin: 1.4rem 0 1rem; }
        img{
            margin: 0 !important;
        }

        @include setWidthRwd(1701px,1800px){
            margin-left: 1.35em !important;
        }

        @include setWidthRwd(1201px,1700px){
            margin-left: 1.25em !important;
        }

        @include setWidthRwd(1000px,1200px){
            margin-left: 0.5em !important;
        }

        @include setMaxRwd{
            .dropdown-menu-list{
                li{
                    position: relative;
                    margin-bottom: 0.25em;
                    
                    .dropdown-item{
                        padding-left: 0;
                    }

                    .navbar-mobile-icon{
                        right: -1.3em;
                        top: -4px;
                    }
                }
            }
        }

        @include pad1080{
            &::after{
                display: none;
            }

            &::before {
                position: absolute;
                display: block;
                top: 24px;
                left: 5px;
                width: 11px;
                height: 1px;
                content: "";
                border: none;
                background-color: #000;
            }

            // 語言在手機的樣式
            &.nav-item-lang{
                .nav-link{
                    .icon{
                        &:last-child{
                            display: none !important;
                        }
                    }
                }
        
                .dropdown-menu {
                    position: relative;
                    opacity: 1;
                    max-height: 100%;
                    display: flex;
                    visibility: visible;
                    padding: 0 !important;
                    transform: translate3d(0, 5px, 0);
                    .dropdown-item {
                        @include fontSize(16px);
                        padding: 5px 0px;
                        text-align: center;
                        line-height: 2;
                        background-color: $colorMain;
                        color:#fff;
                        border-radius: 0;
                        &+.dropdown-item{
                            margin-left: 4px;
                        }
                    }
                }
            }
        }
    }
}

/* #Primary style
================================================== */

.bg-light {
	background-color: #fff !important;
    transition: all 200ms linear;
}

.dropdown{
    &-menu {
        padding: 10px!important;
        margin: 0;
        font-size: 13px;
        letter-spacing: 1px;
        color: #212121;
        background-color: #fcfaff;
        border: none;
        border-radius: 3px;
        box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
        transition: all 200ms linear;
    }

    &-toggle{
        &::after {
            display: none;
        }
    }

    &-item {
        font-size: 14px;
        line-height: 1.5;
        transition: all 200ms linear;
        &:hover, 
        &:focus {
            color: #fff;
            background-color: $colorMain;
        }
    }
}

.nav-item{
    .dropdown-menu {
        transform: translate3d(0, 10px, 0);
        visibility: hidden;
        opacity: 0;
        min-width: 7.75em;
        max-height: 0;
        display: none;
        padding: 0;
        margin: 0;
        transition: all 200ms linear;
        &.show{
            display: block;
            opacity: 1;
            visibility: visible;
            max-height: 999px;
            transform: translate3d(0, 0, 0);
        }

        @include setMinRwd{
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
        }

        @include setMaxRwd{
            padding-top: 0 !important;

            .dropdown-menu-list{
                padding-left: 10px;
                li{
                    margin-bottom: 0.5em;
                }
            }
        }
    }
    
    &-lang {
        position: relative !important;
        .dropdown-toggle{
            @include setMinRwd{
                border: #000 1px solid;
                border-radius: 20px;
                line-height: 1.75;
                padding: 5px 20px 2px !important;
            }

            @include pad1080{
                display: none !important;
            }
        }
    }
}

// 手機Header放置主ICON
.nabar-icon-mobile{
    @include pad1080{
        display: flex !important;
        align-items: center;
        justify-content: flex-end;
        .icon{
            margin-left: 5px;
        }
    }
}

// 搜尋後的表單
.search-form {
    &List{
        position: absolute;
        top: 83px;
        width: 100%;
        height: 100vh;
        background: rgba(0,0,0,.75);
        z-index: 1;
        transition: 0.5s;
        display: none;
        &.open {
            display: block;
        }

        @include setWidthRwd(1025px,1100px){ 
            top: 10.3em;
        }

        @include setWidthRwd(800px,1024px){ 
            top: 8.75em;
        }

        @include pad{
            top: 5.2em;
        }
    }
}