// 聯絡我們
.contact{
    &-bg{
        @include setBackSet(top left,auto);
        background-image: url('../images/contact_bgInfo.png');
        @include setMinRwd{
            padding-bottom: 120px;
        }

        @include setWidthRwd(1251px,1450px){
            padding-bottom: 140px;
        }

        @include pad1080{
            background-size: 100%;
            padding-bottom: 0;
        }
    }

    &-wrapper{
        position: relative;
        @extend %setFlex;
        padding-top:1em;
        padding-bottom:1.5em;

        .btn-wrap{
            flex-wrap: wrap;

            .btn{
                @include fontSize(18px);
                border-radius: 25px !important;
                padding: 5px 2rem;
            }

            @include setWidthRwd(1100px,1400px){
                .btn{
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                }
            }

            @include setMinRwd{
                justify-content: space-between;
                li{
                    flex: none;
                    margin-left: 0 !important;
                    &.contact-btn-option{
                        flex: 1;
                        .btn-wrap {
                            justify-content: flex-end;
                        }
                    }
                }
            }

            @include setMaxRwd{
                li{
                    text-align: center;
                    margin: 0  5px !important;
                }

                .btn{
                    width: 100%;
                }
            }
        }

        // 沿用產品搜尋
        .pro-search-list{
            flex-direction: column;
            align-items: flex-start;
            &Name{
                font-family:'Microsoft JhengHei', '微軟正黑體';
                margin-bottom: 0.5rem;
            }

            &Form {
                width: 100%;
            }

            textarea{
                height: auto;
            }

            @include setMaxRwd{
                margin-bottom: 0.5rem;
            }
        }

        @include setMinRwd{
            padding-top:3em;

            .radio_style {
                margin-top: 0 !important;
            }
        }
    }
    
    // 基本資訊使用
    &-map{
        @include setWidthRwd(1301px,1600px){
            max-width: 35em;
        }

        @include setWidthRwd(1100px,1300px){
            max-width: 30em;
        }
    }

    // 表單使用
    &-form {
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
        @include setMinRwd{
            flex: 1;
            padding-top: 60px;
            padding-left:50px;
        }

        &-flex{
            &,
            &-list{
                @extend %setFlex;
                @include setMinRwd{
                    align-items: center;
                }
            }
        }
    }

    // 置放按鈕的
    &-btn{
        &-wrap{
            justify-content: space-between;
            padding-top: 0;
        }

        &-option{
            .btn-wrap{
                li{
                    & ~ li{
                        margin-left: 5px !important;

                        @include setMinRwd{
                            margin-left: 1.5em !important;
                        }
                    }
                }
            }
        }
    }
}


