.pro{
    &-in-content{
        background-color: #f1f0f0;
        padding: 1rem;
        @include setMinRwd{
            padding-top: get-vw(60px);
            padding-bottom: get-vw(60px);
            margin-left: -1em;
            margin-right: -1em;
        }
    
        @include mobileSmall{
            padding: 0.5rem; 
        }
    }

    &-card-wrap{
        @include setMinRwd{
            margin-top: 2.5em;
        }

        // 列表
        .card{
            background-color: transparent;
    
            // 
            &-title{
                @include fontSize(20px);
                font-family: $OpenSans-300;
                color:$colorMain;
                margin-bottom: 0.2rem;
                @extend .fw-600;
                @include setMaxRwd{
                    @include fontSize(18px);
                }
            }

            &-body{
                padding: 0.75em 0.25em;
                @include setMaxRwd{
                    padding-top: 0.5em;
                }
            }
    
            &-caption{
                @include fontSize(21px);
                color: $colorBase;
                padding: 0;
                @include setMaxRwd{
                    @include fontSize(15px);
                }
            }
    
            &-img-top{
                background-color: #fff;
                box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2) inset;
                overflow: hidden;
                height: 0;
                padding-bottom: 100%;
                img{ 
                    @extend %imgCenter;
                    width: auto;
                    transition: 0.25s;
                }
            }

            &-linkNormal{
                @include setMinRwd{
                    &:hover{
                        & ~ .card-img-top{
                            img{
                                transform: scale(1.25);
                            }
                        }
                    }
                }
            }
        }
    }

    &-detail-blockquote{
        box-shadow: none;
        @include setMinRwd{
            margin-left: 1em;
        }
    }

    // 搜尋頁
    &-search{
        &-list{
            @extend %setFlex-center;
            
            &Name{
                min-width: 100px;
                padding-right: 20px;
                @extend .title-left-circle;
                font-family: $OpenSans-300;
                &::before{
                    @include setSize(6px,6px);
                    background-color: $colorMain;
                    border:none;
                    top: 12px;
                }

                @include setMinRwd{
                    @include fontSize(19px);
                }
            }

            &Form{
                flex: 1;
                margin-bottom: 1rem;
            }

            // 
            .form-control{
                height: 43px;
            }
            
            select{
                appearance: auto;
            }

            @include setMinRwd{
                margin-bottom: 0.75rem;
            }

            
            .form-control,
            .btn{
                border-radius: 2px !important;
            }
        }

        &-wrap{
            .form-control,
            .btn{
                font-family: $OpenSans-300;
            }

            .btn{
                padding-left: 50px;
                padding-right: 50px;
                &-wrap{
                    padding-left: 13px;
                    @include setMinRwd{
                        margin-top: 1.25rem;
                    }
                }

                @include setMaxRwd{
                    width: 100%;
                }
            }
        }

        // 搜尋後內容框
        &Info{
            &-wrap{
                background-color: #fff;
                border: #dcdcdc 1px solid;
                padding: 20px 10px;
                margin: 26px 13px 6.5px;

                @include setMaxRwd{
                    padding: 0.5em;
                }
            }
        }

        // 表格
        &Table{
            margin-bottom: 0;
            th,
            td{
                text-align: center;
                border-bottom: none;
            }
            
            th{
                @include setBackSet(center,cover);
                background-image: url('../images/table-th-bg.jpg');
            }

            td{
                padding-top: 0.75rem;
                padding-bottom: 0;
            }
        }

        // RawData
        &Data{
            &-box{
                height: 250px;
                margin: 0.5em 1em;
                @include setMaxRwd{
                    width: 40em;
                    height: 100%;
                    margin: 1em 0.5em;
                }
            }

            &-txt{
                width: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: space-around;
                p{
                    margin-bottom: 0.1rem;
                }
            }
        }
    }
}

#my-scrollbar {
    width: 100%;
    overflow: auto;
}

.scrollbar-track-x,
.scrollbar-track-y {
    display: none;
}

.scrollbar-track-y {
    background: #d2d2d2 !important;
    border-radius: 4px !important;
}

.scrollbar-thumb {
    top: 2px !important;
    background: linear-gradient(to bottom, #ffffff 4%,#e6e6e6 100%) !important;

    @include setMinRwd{
        width: 6px !important;
        left: 1px !important;
    }

    @include setMaxRwd{
        height: 6px !important;
    }
}
