// 產品項目說明
.pro-heading{
    &-des{
        border-bottom: 1px solid #676767;
        margin-top: 20px;
        margin-bottom: 45px;
    }

    &-img{
        margin-bottom: 20px;
    }

    &-txt{
        color:#464545;
        @include fontSize(18px);
        padding: 10px 10px 15px;
    }
}

// 產品詳細頁
.Prod{
    &-conInfo{
        @include setMinRwd{
            display: flex;
            align-items: flex-start;
            margin-top: 3.5em;
        }

        &-img{
            overflow: hidden;
            @include setMinRwd{
                flex-basis: 650px;
            }

            @include setWidthRwd(1200px,1600px){
                flex-basis: 500px;
            }

            @include setWidthRwd(1100px,1199px){
                flex-basis: 400px;
            }

            @include setMaxRwd{
                padding-top: 0.5em;
            }

            .swiper {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                &-slide {
                    border: #ededed 1px solid;
                    background-color: #fff;
                    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2) inset;
                    overflow: hidden;
                    img {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        max-height: 100%;
                        margin: auto;
                    }
                }

                &-button-next,
                &-button-prev {
                    color: #000;
                    top: auto;
                    // bottom: get-vw(-95px);
                    bottom: -95px;
                    &::after{
                        @include fontSize(20px);
                        @extend .fw-900;
                    }
                    
                    @include setWidthRwd(1200px,1600px){
                        bottom: -90px;
                    }

                    @include setWidthRwd(1100px,1199px){
                        bottom: get-vw(-140px);
                    }
                    
                    @include pad{
                        bottom: -6.5em;
                    }
                    
                    @include mobile{
                        bottom: -4.5em;
                    }
                }

                @include setMaxRwd{
                    &-button-next{
                        right: -1%;
                    }

                    &-button-prev{
                        left: -1%;
                    }
                }
            }
            
            // 大圖
            .Prod_mySwiper2 {
                height: 80%;
                width: 100%;
                margin-bottom: 0.25em;
                .swiper {
                    &-slide {
                        padding-bottom: 100%;
                    }
                }
                @include setMinRwd{
                    margin-bottom: 15px;
                }
            }
            
            // 縮略圖
            .Prod_mySwiper {
                height: 20%;
                box-sizing: border-box;
                padding: 10px 0;
                .swiper{
                    &-slide {
                        padding-bottom: 17%;
                        opacity: 0.4;
                        img{
                            max-width: 35px;
                        }

                        &-thumb-active {
                            opacity: 1;
                        }

                        @include setWidthRwd(1200px,1600px){
                            padding-bottom: 20%;
                        }

                        @include setWidthRwd(1100px,1199px){
                            padding-bottom: 25%;
                        }

                        @include mobile{
                            padding-bottom: 23%;
                        }
                    }
                }
            }
        }

        &-info{
            padding-top: 0.5em;
            @include setMinRwd{
                flex: 1;
                padding-left: 3.5em;
            }
        }
    }

    &-heading{
        display: flex;
        line-height: 1.5;
        padding-bottom: 0.25em;
        margin-bottom: 0;

        &Title{
            @include fontSize(25px);
            @extend .fw-600;
            margin-bottom: 20px;

            @extend %setFlex;

            &-num{
                 font-family: $OpenSans-300;
                 color: $colorMain;
                 padding-right: 25px;
            }

            @include setMinRwd{
                @include fontSize(30px);
            }
        }

        &Txt,
        &SubTitle{
            @include fontSize(16px);
            @extend .fw-400;
            @include setMinRwd{
                @include fontSize(18px);
            }
        }

        &Txt{
            position: relative;
            padding-left: 20px;
            &::before{
                content: '';
                display: block;
                @include setSize(8px,8px);
                background-color: #b99576;
                position: absolute;
                left: 0;
                top: 8px;
                transform: rotate(45deg);
            }

            @include setMaxRwd{
                min-width: 150px;
            }
        }
        
        &SubTitle{
            a{
                &:hover{
                    color: $colorMain;
                }
            }

            .icon{
                margin-top: -1px; margin-right: 2px; vertical-align: top;
            }
        }
    }

    &-conTxt{
        margin-bottom: 1.5em;
    }

    &-conSpec{
        @extend %setFlex;
        text-align: center;
        &-item{
            // flex-grow: 1;
            flex-shrink: 1;
            margin-bottom: 15px;
            &Img{
                img{
                    max-width: 40px;
                }
            }

            &Number{
                border-bottom: #cecece 1px solid;
                &_count{
                    color: #626262;
                }
            }

            div{
                padding-left: 10px;
                padding-right: 10px;
                margin-bottom: 5px;
                padding-bottom: 2px;
            }
        }
    }

    &-photo-small{
        padding-left: 1.5em;
        padding-right: 1.5em;
        @include setMinRwd{
            padding-left: 3em;
            padding-right: 3em;
            margin-top: 15px;
        }
    }

    &-socialIcons {
        display: flex;
        margin-top: 30px;
        li {
            margin-right: 10px;
        }
    }

    &-unreset{
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        margin-top: 1em;
        margin-bottom: 1em;
        .nav-tabs{
            border-bottom-color: #bfbfbf;
        }

        .nav-item {
            min-height: auto;
            &::before{
                display: none !important;
            }
        }

        .nav-link{
            position: relative;
            @include fontSize(16px);
            @extend .fw-600;
            background-color: transparent !important;
            border: none;
            padding-right: 30px !important;
            padding-left: 30px !important;

            &.active{
                color: $colorMain !important;
                &::after {
                    content: "";
                    display: block;
                    @include setSize(100%,3px);
                    background-color: $colorMain;
                    position: absolute;
                    bottom: -1px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            @include setMinRwd{
                @include fontSize(19px);
            }
        }

        .tab-content{
            padding: 1em;
            @include setMinRwd{
                padding: 2.5em 2em;
            }
        }
    }
}