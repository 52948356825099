.page{
    &About{
        @include setBackSet(bottom,100%);
        background-image: url('../images/main-pageAbout-boxBg.jpg');
        background-color: #fff;

        padding: get-vw(65px);
        z-index: 1;

        @include setMaxRwd{
            margin-bottom: 2em;
        }

        &::before,
        &::after {
            content: "";
            @include setSize(100%,100%);
            @include setBackSet(center,cover);
            position: absolute;
            z-index: -1;

            @include setMaxRwd{
                background-size: 100%;
            }
        }

        &::before {
            @include setSizeMax(40px,521px);
            background-image: url("../images/main-pageAbout-boxShadow-right.png");
            right: -2.5%;
            top: 0;

            @include setMaxRwd{
                // right: -9.5%;
                display: none;
            }
        }

        &::after {
            content: "";
            @include setSizeMax(564px,40px);
            background-image: url("../images/main-pageAbout-boxShadow-bottom.png");
            left: 0;
            bottom: -5%;

            @include setMaxRwd{
                bottom: -2.5%
            }
        }

        &-img{
            .img-thumbnail{
                background-color: transparent;
                @include radius(0);
                text-align: center;
            }

            @include setMinRwd{
                margin-top: 1vw;

                .img-thumbnail{
                    padding: 0;
                    margin-bottom: 0;
                }
            }
        }

        &-info{
            padding-left: 15px;
            padding-right: 15px;
            &-title{
                width: 100%;

                p{
                    margin: 0;
                }

                @include setMinRwd{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    writing-mode: vertical-lr;
                    hr{
                        border: none;
                        width: 1px;
                        height: 95%;
                        background: #707070;
                        opacity: 1;
                        margin: 0 10px;
                    }
                }
            }
        
            &-name{
                @include fontSize(36px);
                letter-spacing: 6px
            }

            &-subname{
                @include fontSize(18px);
                color: $colorSubText;
                letter-spacing: 2px
            }

            &-article{
                @include fontSize(17px);
                padding-top: get-vw(50px);
                padding-bottom: get-vw(30px);
            }

            .btn{
                display: flex;
                align-items: center;
                background-color: transparent !important;
                border: none !important;
                .icon{
                    transition: var(--card-transition-duration);
                }

                &:hover{
                    color: $colorMain !important;
                    .icon{
                        transform: translateX(10px)
                    }
                }

                @include setMaxRwd{
                    &-wrap{
                        margin-bottom: 1rem;
                    }
                }
            }
            
            @include setMinRwd{
                padding-left: get-vw(70px);
            }
        }
    }
}