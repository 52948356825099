@charset "UTF-8";
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@900&display=swap");
@import url("//fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("//fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("//fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");
@import url("//fonts.googleapis.com/css2?family=Kanit:wght@700&display=swap");
@import url("//fonts.googleapis.com/css2?family=Mitr&display=swap");
@import url("//fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("//fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");
@import url("//fonts.googleapis.com/css2?family=Arimo&display=swap");
@import url("//fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
@import url("//fonts.googleapis.com/css2?family=Work+Sans&display=swap");
.is-wrapper *, a, body {
  color: #262626;
  font-size: 16px;
  font-family: "Microsoft JhengHei", "微軟正黑體", "Arial", sans-serif;
  line-height: 1.65;
  font-weight: 400;
}

input, form, body, html {
  margin: 0px;
  padding: 0px;
}

.contact-form-flex, .contact-form-flex-list, .contact-wrapper, .Prod-conSpec, .Prod-headingTitle, .pageFlex, .page-gallerSwiper-pro .swiper-act-container, .index-pageWrapper, .card-wrap, .box, .pagination-wrap, .heading-title, .mainContent {
  display: flex;
  flex-wrap: wrap;
}

.pro-search-list, .page-gallerSwiper-pro .swiper-act-button, .member-editor-buildCase-tab .btn-wrap .btn, .member-editor-buildCase-tab, .card-time, .btn-wrap, .radio_style, .title-mainWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.pro-card-wrap .card-img-top img, .imgCenter-4-3 img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;
  margin: auto;
}

.font-size-1 {
  font-size: 87.5% !important;
}

.font-size-2 {
  font-size: 100% !important;
}

.font-size-3 {
  font-size: 112.5% !important;
}

.font-size-4 {
  font-size: 150% !important;
}

.font-size-5 {
  font-size: 175.5% !important;
}

.font-size-6 {
  font-size: 250% !important;
}

.font-size-7 {
  font-size: 275% !important;
}

.font-size-8 {
  font-size: 362.5% !important;
}

.font-size-9 {
  font-size: 450% !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 1.75 !important;
}

.line-height-5 {
  line-height: 2 !important;
}

.noJS > div,
.noJS > ul {
  display: none;
}

.noJS > noscript {
  display: block;
  width: 100%;
  line-height: 10em;
}

* {
  box-sizing: border-box;
}

html {
  overflow: -moz-scrollbars-vertical;
}

body {
  background: #fff;
  visibility: visible;
  overflow-x: hidden;
}
body.fixed {
  overflow: hidden;
}

div {
  position: relative;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

form {
  font-size: 16px;
}

label {
  display: inline-flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;
}

input {
  -webkit-autofill: unset;
  font-size: 16px;
  background-color: #eeeeee;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fcfcfc inset;
}
input:focus {
  outline: 0;
}
input[type=text], input[type=password] {
  vertical-align: top;
}
input[type=radio], input[type=checkbox] {
  margin-right: 5px;
  cursor: pointer;
  vertical-align: middle;
}
input[type=number]::-webkit-inner-spin-button,
input [type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
input.hid {
  display: none;
}

a:focus,
input:focus,
select:focus,
button:focus {
  box-shadow: none !important;
  outline: none;
}

select {
  border: 0px;
  outline: none;
}

textarea {
  padding: 5px;
  border: 1px solid rgba(216, 216, 216, 0.8);
  box-sizing: border-box;
  resize: none;
  font-size: 16px;
  background-color: #eeeeee;
}

a {
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
  color: currentColor;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.clear {
  clear: both;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  overflow: hidden;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hide {
  display: none !important;
}
@media (max-width: 1100px) {
  .hide {
    display: block !important;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
}

.mw-auto {
  max-width: none !important;
}

.wrapper-1800 {
  width: 100%;
  max-width: 1800px;
  margin: auto;
}

.wrapper-1750 {
  width: 100%;
  max-width: 1750px;
  margin: auto;
}

.wrapper-1700 {
  width: 100%;
  max-width: 1700px;
  margin: auto;
}

.wrapper-1650 {
  width: 100%;
  max-width: 1650px;
  margin: auto;
}

.wrapper {
  width: 100%;
  max-width: 1600px;
  margin: auto;
}

.wrapper-1550 {
  width: 100%;
  max-width: 1550px;
  margin: auto;
}

.wrapper-1500 {
  width: 100%;
  max-width: 1500px;
  margin: auto;
}

.wrapper-1450 {
  width: 100%;
  max-width: 1450px;
  margin: auto;
}

.wrapper-1400 {
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.wrapper-1350 {
  width: 100%;
  max-width: 1350px;
  margin: auto;
}

.wrapper-1300 {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.wrapper-1250 {
  width: 100%;
  max-width: 1250px;
  margin: auto;
}

.wrapper-1200 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.wrapper-1150 {
  width: 100%;
  max-width: 1150px;
  margin: auto;
}

.wrapper-1100 {
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

.wrapper-1050 {
  width: 100%;
  max-width: 1050px;
  margin: auto;
}

.wrapper-1000 {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.wrapper-900 {
  width: 100%;
  max-width: 900px;
  margin: auto;
}

.wrapper-800 {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

.wrapper-750 {
  width: 100%;
  max-width: 750px;
  margin: auto;
}

.wrapper-700 {
  width: 100%;
  max-width: 700px;
  margin: auto;
}

.wrapper-650 {
  width: 100%;
  max-width: 650px;
  margin: auto;
}

.is-wrapper * {
  letter-spacing: 0px !important;
}

/* #Navigation
================================================== */
.start-header {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-out;
}
.start-header.scroll-on {
  position: fixed;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 10px 0;
  transition: all 0.3s ease-out;
}
@media (min-width: 1101px) {
  .start-header.scroll-on .navbar-brand img {
    transition: all 0.3s ease-out;
  }
}
.start-header .container {
  max-width: 1650px;
}
.start-header .container img {
  margin: 0 !important;
}

.navigation-wrap {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1050;
  transition: all 0.3s ease-out;
}
@media (max-width: 1100px) {
  .navigation-wrap {
    padding: 5px 0;
  }
}

.navbar {
  font-size: 16px;
  font-size: 1rem;
  padding: 0;
}
@media (min-width: 1550px) and (max-width: 1700px) {
  .navbar .navbar-brand {
    max-width: 13em;
    margin-left: 0;
  }
}
@media (min-width: 1050px) and (max-width: 1500px) {
  .navbar .nav-item {
    position: static;
    margin-left: 0.5em !important;
  }
}
@media (min-width: 1070px) and (max-width: 1300px) {
  .navbar {
    flex-direction: column;
    padding-top: 0.5em;
  }
}
@media (min-width: 1000px) and (max-width: 1020px) {
  .navbar {
    align-items: flex-start;
  }
}
@media (max-width: 1080px) {
  .navbar {
    justify-content: space-between;
  }
}
.navbar-brand {
  padding: 0;
  margin: 0;
}
.navbar-brand img {
  display: block;
  transition: all 0.3s ease-out;
}
@media (min-width: 1400px) {
  .navbar-brand {
    margin-left: 6.5789473684vw;
  }
}
@media (max-width: 1080px) {
  .navbar-brand {
    max-width: 62.5vw;
  }
}
.navbar-light .navbar-toggler-icon {
  width: 25px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #000;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon::after, .navbar-light .navbar-toggler-icon::before {
  width: 25px;
  position: absolute;
  height: 1px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon::after {
  top: 8px;
}
.navbar-light .navbar-nav .nav-link {
  display: inline-flex;
}
.navbar-collapse {
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (min-width: 1101px) {
  .navbar-collapse {
    padding-top: 25px;
    padding-bottom: 15px;
  }
}
@media (min-width: 800px) and (max-width: 1500px) {
  .navbar-collapse {
    width: 100%;
    margin-top: 0;
    flex-wrap: nowrap;
  }
}
@media (min-width: 1070px) and (max-width: 1300px) {
  .navbar-collapse {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 1080px) {
  .navbar-collapse {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: -100%;
    background: #fff;
    transition: 0.15s;
    overflow-y: auto;
    max-width: 60vw;
  }
  .navbar-collapse, .navbar-collapse > ul {
    z-index: 100;
  }
  .navbar-collapse > ul {
    position: relative;
    justify-content: flex-start !important;
  }
  .navbar-collapse.show {
    display: block !important;
    left: 0;
  }
}
.navbar-overlay {
  display: block;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
}
.navbar-overlay.active {
  opacity: 1;
  visibility: visible;
}
@media (min-width: 1101px) {
  .navbar-overlay {
    display: block;
  }
}
.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}
.navbar-toggler:active, .navbar-toggler:focus {
  outline: none;
}
@media (max-width: 1080px) {
  .navbar-toggler {
    display: block !important;
  }
}
.navbar-toggler .hamburger-toggle {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  z-index: 11;
}
.navbar-toggler .hamburger-toggle .hamburger {
  position: absolute;
  transform: translate(-50%, -50%) rotate(0deg);
  left: 50%;
  top: 50%;
  width: 50%;
  height: 50%;
  pointer-events: none;
}
.navbar-toggler .hamburger-toggle .hamburger span {
  width: 100%;
  height: 4px;
  position: absolute;
  background: #000;
  border-radius: 2px;
  z-index: 1;
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), all 0.2s ease-in-out;
  left: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger span:first-child {
  top: 10%;
  transform-origin: 50% 50%;
  transform: translate(0% -50%) !important;
}
.navbar-toggler .hamburger-toggle .hamburger span:nth-child(2) {
  top: 50%;
  transform: translate(0, -50%);
}
.navbar-toggler .hamburger-toggle .hamburger span:last-child {
  left: 0;
  top: auto;
  bottom: 0;
  transform-origin: 50% 50%;
}
.navbar-toggler .hamburger-toggle .hamburger.active span {
  position: absolute;
  margin: 0;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:first-child {
  top: 45%;
  transform: rotate(45deg);
}
.navbar-toggler .hamburger-toggle .hamburger.active span:nth-child(2) {
  left: 50%;
  width: 0px;
}
.navbar-toggler .hamburger-toggle .hamburger.active span:last-child {
  top: 45%;
  transform: rotate(-45deg);
}
.navbar-toggler.fixed {
  display: none !important;
  position: fixed;
  left: 59%;
  top: 2%;
  z-index: 1001;
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span {
  background-color: #fff;
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:first-child {
  transform: rotate(40deg);
}
.navbar-toggler.fixed .hamburger-toggle .hamburger span:last-child {
  transform: translateY(-18px) rotate(-40deg);
}
.navbar-toggler.fixed.active {
  display: block !important;
}
.navbar-nav.link {
  flex: 1;
  justify-content: space-between;
}
.navbar-nav.link .dropdown-menu {
  box-shadow: none;
  background: transparent;
}
.navbar-nav.link .dropdown-menu-list li .dropdown-item {
  font-size: 16px;
  font-size: 1rem;
  background-color: transparent !important;
  white-space: normal;
}
.navbar-nav.link .dropdown-menu-list li .dropdown-item:hover {
  color: #004b7c !important;
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item {
  position: relative;
  font-size: 14px;
  font-size: 0.875rem;
  color: #666;
  padding-left: 1.25rem;
}
.navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item::before {
  content: "";
  display: block;
  width: 10px;
  height: 1px;
  background-color: #696969;
  position: absolute;
  top: 12.5px;
  left: 0;
}
@media (max-width: 1100px) {
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier {
    padding-top: 0;
    margin-top: -2px;
    padding-left: 20px;
    max-height: 55vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
    position: static;
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item {
    font-size: 15px;
    font-size: 0.9375rem;
    background-color: transparent !important;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier .dropdown-item:last-child {
    border-bottom: none;
  }
  .navbar-nav.link .dropdown-menu-list li .dropdown-menu-subContanier.active {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
@media (min-width: 1101px) {
  .navbar-nav.link {
    position: relative;
    flex: none;
    min-width: calc(740px - 2em);
    margin-left: auto;
    padding-right: 1em;
  }
  .navbar-nav.link .nav-link {
    text-transform: uppercase;
    flex-direction: column;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    background: #fff;
    max-height: 100vw;
    left: 0;
    padding: 0 !important;
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0);
    z-index: 1;
    min-width: 100vw;
    max-width: 100%;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu::before {
    content: "";
    display: block;
    width: 1000%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    left: -100%;
    top: 14px;
    z-index: -1;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu::after {
    content: "";
    width: 1000%;
    height: 1px;
    background-color: #eee;
    display: block;
    position: absolute;
    top: 14px;
    left: -100%;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu .dropdown-menu-list {
    width: 100%;
    max-width: 1200px;
    padding: 10px 10px 0;
    margin: 15px auto 0;
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 15px;
    position: relative;
    justify-content: center;
    left: calc(50vw - 50px);
    transform: translateX(calc(-100vw + 100px));
  }
  .navbar-nav.link .dropdown-item:hover {
    background-color: transparent;
    color: #000;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .navbar-nav.link {
    min-width: calc(100% - 120px);
    margin-left: 0;
    padding-left: 1em;
    justify-content: space-around;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu {
    min-width: 100%;
    top: 3.4em;
    background-color: transparent;
  }
  .navbar-nav.link .nav-item:hover .dropdown-menu .dropdown-menu-list {
    left: calc(50% + 20px);
    transform: translateX(calc(-50% + 20px));
  }
}
@media (min-width: 1000px) and (max-width: 1100px) {
  .navbar-nav.link {
    flex: 1;
    margin: auto;
    padding-right: 1em;
    justify-content: space-around;
  }
}
@media (max-width: 1080px) {
  .navbar-nav.link {
    flex-direction: column;
  }
  .navbar-nav.link .nav-item {
    padding-left: 1.5rem !important;
  }
}
@media (max-width: 1080px) {
  .navbar-nav.info {
    flex-wrap: wrap;
  }
  .navbar-nav.info > li {
    flex: 1;
  }
  .navbar-nav.info > li.nav-item-social {
    display: none;
  }
  .navbar-nav.info > li.nav-item-lang {
    flex: 0 0 calc(100% - 3rem);
    max-width: calc(100% - 3rem);
    margin-top: 0.5rem;
    order: 1;
  }
}
.navbar-mobile-icon {
  width: 80px;
  height: 40px;
  color: #000 !important;
  text-align: right;
  padding-right: 18px;
  position: absolute;
  right: 0;
  top: 5px;
  line-height: 40px;
}
.navbar-mobile-icon::after {
  display: none;
}
.navbar-mobile-icon.show .bi {
  transform: rotate(180deg);
}

.nav-link {
  position: relative;
  display: inline-block;
  color: #262626 !important;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 500;
  padding: 5px 0 !important;
  transition: all 200ms linear;
}
@media (min-width: 1101px) {
  .nav-link {
    line-height: 3;
  }
}
@media (max-width: 1080px) {
  .nav-link {
    padding: 10px 0 !important;
  }
}
.nav-item {
  position: relative;
  transition: all 200ms linear;
}
.nav-item.active .nav-link, .nav-item:hover .nav-link {
  color: #004b7c !important;
}
.nav-item .icon {
  display: inline-block;
  vertical-align: top;
  flex: none;
  margin-top: -2px;
}
.nav-item .icon .bi-caret-down-fill {
  font-size: 14px;
  color: #004b7c;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  margin-top: -3px;
}
.nav-item img {
  margin: 0 !important;
}
@media (min-width: 1701px) and (max-width: 1800px) {
  .nav-item {
    margin-left: 1.35em !important;
  }
}
@media (min-width: 1201px) and (max-width: 1700px) {
  .nav-item {
    margin-left: 1.25em !important;
  }
}
@media (min-width: 1000px) and (max-width: 1200px) {
  .nav-item {
    margin-left: 0.5em !important;
  }
}
@media (max-width: 1100px) {
  .nav-item .dropdown-menu-list li {
    position: relative;
    margin-bottom: 0.25em;
  }
  .nav-item .dropdown-menu-list li .dropdown-item {
    padding-left: 0;
  }
  .nav-item .dropdown-menu-list li .navbar-mobile-icon {
    right: -1.3em;
    top: -4px;
  }
}
@media (max-width: 1080px) {
  .nav-item::after {
    display: none;
  }
  .nav-item::before {
    position: absolute;
    display: block;
    top: 24px;
    left: 5px;
    width: 11px;
    height: 1px;
    content: "";
    border: none;
    background-color: #000;
  }
  .nav-item.nav-item-lang .nav-link .icon:last-child {
    display: none !important;
  }
  .nav-item.nav-item-lang .dropdown-menu {
    position: relative;
    opacity: 1;
    max-height: 100%;
    display: flex;
    visibility: visible;
    padding: 0 !important;
    transform: translate3d(0, 5px, 0);
  }
  .nav-item.nav-item-lang .dropdown-menu .dropdown-item {
    font-size: 16px;
    font-size: 1rem;
    padding: 5px 0px;
    text-align: center;
    line-height: 2;
    background-color: #004b7c;
    color: #fff;
    border-radius: 0;
  }
  .nav-item.nav-item-lang .dropdown-menu .dropdown-item + .dropdown-item {
    margin-left: 4px;
  }
}

/* #Primary style
================================================== */
.bg-light {
  background-color: #fff !important;
  transition: all 200ms linear;
}

.dropdown-menu {
  padding: 10px !important;
  margin: 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #212121;
  background-color: #fcfaff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  transition: all 200ms linear;
}
.dropdown-toggle::after {
  display: none;
}
.dropdown-item {
  font-size: 14px;
  line-height: 1.5;
  transition: all 200ms linear;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  background-color: #004b7c;
}

.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  min-width: 7.75em;
  max-height: 0;
  display: none;
  padding: 0;
  margin: 0;
  transition: all 200ms linear;
}
.nav-item .dropdown-menu.show {
  display: block;
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(0, 0, 0);
}
@media (min-width: 1101px) {
  .nav-item .dropdown-menu {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
@media (max-width: 1100px) {
  .nav-item .dropdown-menu {
    padding-top: 0 !important;
  }
  .nav-item .dropdown-menu .dropdown-menu-list {
    padding-left: 10px;
  }
  .nav-item .dropdown-menu .dropdown-menu-list li {
    margin-bottom: 0.5em;
  }
}
.nav-item-lang {
  position: relative !important;
}
@media (min-width: 1101px) {
  .nav-item-lang .dropdown-toggle {
    border: #000 1px solid;
    border-radius: 20px;
    line-height: 1.75;
    padding: 5px 20px 2px !important;
  }
}
@media (max-width: 1080px) {
  .nav-item-lang .dropdown-toggle {
    display: none !important;
  }
}

@media (max-width: 1080px) {
  .nabar-icon-mobile {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
  }
  .nabar-icon-mobile .icon {
    margin-left: 5px;
  }
}

.search-formList {
  position: absolute;
  top: 83px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1;
  transition: 0.5s;
  display: none;
}
.search-formList.open {
  display: block;
}
@media (min-width: 1025px) and (max-width: 1100px) {
  .search-formList {
    top: 10.3em;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .search-formList {
    top: 8.75em;
  }
}
@media (max-width: 810px) {
  .search-formList {
    top: 5.2em;
  }
}

footer {
  padding: 1em;
  background-color: #010101;
}
footer, footer a {
  color: #fff;
}
@media (min-width: 1101px) {
  footer .footer-wrapper {
    display: flex;
    justify-content: space-between;
  }
}
footer .footer-info p {
  margin-bottom: 10px;
}
footer .footer-info .copyright {
  font-size: 15px;
  font-size: 0.9375rem;
}
footer .footer-info-backTop a {
  font-size: 16px;
  font-size: 1rem;
  color: #e50012;
}
footer .footer-info-backTop span {
  padding-right: 5px;
}
@media (max-width: 1100px) {
  footer .footer-info-backTop {
    display: none;
  }
}
@media (min-width: 1101px) {
  footer .footer-info-left .item {
    display: flex;
    justify-content: space-between;
  }
  footer .footer-info-left .item p ~ p {
    padding-left: 20px;
  }
}
footer .footer-info-left a {
  color: #e50012;
}
@media (max-width: 1100px) {
  footer .footer-info-left p {
    margin-bottom: 0.875rem;
  }
}
footer .footer-info-right .iware {
  padding-left: 0 !important;
  padding-right: 0;
}
@media (min-width: 1101px) {
  footer .footer-info-right {
    text-align: right;
  }
}
@media (max-width: 1100px) {
  footer .footer-info-right {
    margin-top: 15px;
  }
  footer .footer-info-right p {
    font-size: 13px !important;
  }
}
@media (min-width: 1101px) {
  footer {
    padding: 45px 25px 40px;
  }
}
@media (max-width: 1100px) {
  footer {
    padding-bottom: 4em;
  }
}

.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
}
@media (min-width: 800px) and (max-width: 1000px) {
  .divider {
    display: none;
  }
}

.iware {
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  margin-bottom: 0 !important;
  padding: 4px 8px;
}
.iware, .iware a {
  color: #bfbfbf;
}
.iware a:hover {
  text-decoration: underline;
}
.iware b {
  font-weight: 100;
}

.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
}
.float-link .link-btn a:hover {
  opacity: 0.65;
}
@media (min-width: 1101px) {
  .float-link .link-btn.nabar-icon-mobile {
    display: none;
  }
}
.float-link .link-btn.nabar-icon-mobile img {
  max-width: 100%;
}
@media (max-width: 1100px) {
  .float-link .link-btn.top {
    display: none;
  }
}
.float-link.active {
  right: 15px;
}
@media (max-width: 1600px) {
  .float-link.active {
    right: 10px;
  }
}
@media (max-width: 1100px) {
  .float-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    background: #e50012;
    padding: 5px;
    margin: 0;
  }
  .float-link .link-btn {
    text-align: center;
    padding: 6px;
  }
  .float-link .link-btn,
.float-link .link-btn .link-btn-toggle {
    display: block;
    width: 100%;
  }
  .float-link .link-btn .link-btn-toggle {
    color: #fff;
  }
  .float-link .link-btn .icon {
    margin-left: 0;
  }
  .float-link .link-btn .icon small {
    font-size: 100%;
    padding-right: 5px;
  }
}

.article,
article {
  width: 100%;
  font-size: 100%;
}
.article iframe,
article iframe {
  width: 100%;
  height: 750px;
}
@media (max-width: 810px) {
  .article iframe,
article iframe {
    height: 350px;
  }
}

.in-content {
  padding-top: 2.6315789474vw;
  padding-bottom: 2.6315789474vw;
}
@media (max-width: 1100px) {
  .in-content {
    padding-top: 3.90625vw;
    padding-bottom: 3.90625vw;
  }
}

.imgCenter-4-3 {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 59.5%;
  overflow: hidden;
}
.imgCenter-4-3 img {
  max-height: none;
}

.g-recaptcha {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (max-width: 1100px) {
  .g-recaptcha {
    margin-bottom: 1rem;
  }
}

.fw-400, .Prod-headingTxt, .Prod-headingSubTitle {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600, .Prod-unreset .nav-link, .Prod-headingTitle, .pro-card-wrap .card-title {
  font-weight: 600 !important;
}
.fw-900, .Prod-conInfo-img .swiper-button-next::after, .Prod-conInfo-img .swiper-button-prev::after, .page-gallerSwiper-pro .swiper-act-button .swiper-button-next::after,
.page-gallerSwiper-pro .swiper-act-button .swiper-button-prev::after, .index-pageHead, .card-btn-more, .card-time-date, .heading-title-txt, .heading-title-txt-en, .title-ch, .title-en, .link-head {
  font-weight: 900 !important;
}

.boxShadow, .member-content .form-box {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1) !important;
}

.bt-solid-c1 {
  border-top: 1px #ccc solid;
}

.mainContent {
  overflow: hidden;
}
.mainContent-wrap {
  padding-bottom: 2em;
  background-color: #f8f8f8;
}
@media (max-width: 1100px) {
  .mainContent-wrap {
    padding-top: 1em;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 1101px) {
  .mainContent-info {
    width: 300px;
  }
  .mainContent-info ~ .mainContent-list {
    max-width: calc(100% - 300px);
    padding-left: 80px;
  }
}
@media (max-width: 1100px) {
  .mainContent-info {
    display: none;
  }
}
.mainContent-list {
  max-width: 100%;
  flex: 1;
}
@media (max-width: 1100px) {
  .mainContent-list {
    padding: 0.5em 0.1em;
  }
}
@media (max-width: 1024px) {
  .mainContent {
    flex-direction: column;
  }
}

.link-wrap {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0px 10px 20px 0;
}
.link-wrap ul > li > a {
  position: relative;
  display: block;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.5;
  color: #262626;
  padding: 0.75em 50px 0.75rem 30px;
  border-bottom: dotted 1px #777;
}
.link-wrap ul > li > a::after {
  content: "\f4fe";
  font-family: "bootstrap-icons";
  display: block;
  color: #000;
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -15px;
  font-weight: 100;
  font-size: 22px;
  font-size: 1.375rem;
}
.link-wrap ul > li > a:hover, .link-wrap ul > li > a.active {
  background-color: #004b7c;
}
.link-wrap ul > li > a:hover, .link-wrap ul > li > a:hover::after, .link-wrap ul > li > a.active, .link-wrap ul > li > a.active::after {
  color: #fff;
}
.link-wrap ul > li > a:hover::after, .link-wrap ul > li > a.active::after {
  content: "\f2ea";
}
.link-wrap ul > li > a.active {
  border-top-right-radius: 30px;
}
.link-wrap ul > li > ol a {
  position: relative;
  display: block;
}
.link-wrap ul > li > ol li {
  border-bottom: #fff 1px dotted;
  padding-left: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.link-wrap ul > li > ol li:first-child {
  padding-top: 15px;
}
.link-wrap ul > li > ol li a:hover, .link-wrap ul > li > ol li a.active {
  color: #004b7c;
}
.link-wrap .first_menu.open {
  display: block;
}
@media (min-width: 1101px) {
  .link-wrap .first_menu {
    display: none;
  }
}
@media (min-width: 1020px) and (max-width: 1500px) {
  .link-wrap a {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media (max-width: 1024px) {
  .link-wrap {
    margin-bottom: 1rem;
  }
}
.link-head {
  font-size: 30px;
  font-size: 1.875rem;
  color: #004b7c;
  padding-left: 0.75rem;
  margin-bottom: 1.5rem;
}

.unit {
  position: relative;
  display: block;
  line-height: 1.5;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.unit .btn {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.unit-icon {
  padding-left: 1.5em !important;
  padding-right: 0;
}
.unit-icon .icon {
  margin-top: 0;
  position: absolute;
  left: 0;
  top: -0.1rem;
}
@media (max-width: 1100px) {
  .unit {
    text-align: left;
    font-size: 16px;
  }
  .unit-icon .icon {
    top: 0.85rem;
  }
}

.select_style::after {
  content: "\f282";
  font-family: "bootstrap-icons" !important;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  position: absolute;
  right: 8px;
  top: 1px;
}
.select_style select.form-control {
  background-color: #fff;
  border: 1px solid #e6eaea !important;
  text-align: left;
  border-radius: 0;
  height: 40px !important;
  min-height: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.select_style select {
  width: 100%;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
}
.select_style select,
.select_style input {
  border: none !important;
  height: 100% !important;
}
.select_style .form-control {
  border-radius: 0;
}
.select_style .disabled {
  background-color: #e9ecef;
}
.select_style .disabled select,
.select_style .disabled input {
  background: transparent;
}

.title-wrap {
  width: 100%;
  text-align: center;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.title-wrap .btn .icon {
  font-size: 23px;
  font-size: 1.4375rem;
}
@media (max-width: 1100px) {
  .title-wrap {
    margin-bottom: 2px;
  }
}
.title-spacing-2 {
  letter-spacing: 2px;
}
.title-spacing-3 {
  letter-spacing: 3px;
}
.title-spacing-4 {
  letter-spacing: 4px;
}
.title-spacing-5 {
  letter-spacing: 5px;
}
.title-en {
  font-family: "Mitr", sans-serif;
  font-size: 35px;
  font-size: 2.1875rem;
  text-transform: uppercase;
  margin-bottom: 0;
}
@media (max-width: 810px) {
  .title-en {
    font-size: 6.25vw;
  }
}
.title-ch {
  position: relative;
  font-size: 45px;
  font-size: 2.8125rem;
  color: #595757;
}
@media (max-width: 810px) {
  .title-ch {
    font-size: 4.375vw;
  }
}
@media (max-width: 640px) {
  .title-ch {
    font-size: 7.5vw;
  }
}
.title-mainWrap {
  justify-content: space-between;
  padding-top: 0.25em;
}
@media (max-width: 1100px) {
  .title-mainWrap {
    padding-top: 0.65em;
    padding-bottom: 0.65em;
  }
}
.titleStyle {
  position: relative;
  padding: 0.25em;
}
.titleStyle::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: #e50012;
  border-radius: 50%;
  position: absolute;
  right: -8px;
  top: 8px;
}
.title__topLine {
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: #000;
}
.title__solid {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #b3b3b3;
}
.title__underLine {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #000;
}
.title-left-circle, .pro-search-listName {
  position: relative;
  padding-left: 30px;
  z-index: 1;
}
.title-left-circle::before, .pro-search-listName::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border: #004b7c 2px solid;
  border-radius: 50%;
  top: 11px;
  left: 10px;
  z-index: -1;
}
.title-right-squre {
  position: relative;
  padding-left: 15px;
  z-index: 1;
}
.title-right-squre::before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #0125fd;
  position: absolute;
  bottom: 2px;
  right: -40px;
  z-index: -1;
}
.title__topHead::before {
  content: "";
  display: block;
  width: 30px;
  height: 0.5rem;
  background-color: #004b7c;
  margin-bottom: 1rem;
}

.heading-title {
  align-items: flex-end;
  margin-bottom: 1em;
}
.heading-title-txt, .heading-title-txt-en {
  position: relative;
  font-size: 2.1052631579vw;
  letter-spacing: 4px;
  padding-left: 65px;
  padding-right: 0.5rem;
}
.heading-title-txt::before, .heading-title-txt-en::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  max-width: 50px;
  max-height: 43px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../images/icon-title.png");
  position: absolute;
  left: 0;
  top: 4px;
}
@media (max-width: 1100px) {
  .heading-title-txt, .heading-title-txt-en {
    font-size: 23px;
    font-size: 1.4375rem;
    display: flex;
    flex-direction: column;
    padding-left: 58px;
  }
  .heading-title-txt::before, .heading-title-txt-en::before {
    top: 0;
  }
}
.heading-title-txt-en {
  font-size: 1.3157894737vw;
  letter-spacing: 0;
  text-transform: uppercase;
  padding-left: 55px;
}
@media (max-width: 1100px) {
  .heading-title-txt-en {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.heading-title small {
  letter-spacing: 0;
  font-size: 16px;
  color: #7d7d7d;
}
.heading-title::before, .heading-title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -20px;
}
.heading-title::before {
  width: 100%;
  height: 100%;
  max-width: 150px;
  max-height: 6px;
  border-top: #004b7c 2px solid;
  z-index: 1;
}
.heading-title::after {
  width: 100%;
  height: 6px;
  border-top: #dcdcdc 2px solid;
  border-bottom: #ffffff 4px solid;
}

.breadcrum-wrap {
  margin-top: 0.7894736842vw;
  margin-bottom: 0.7894736842vw;
}
@media (max-width: 1600px) {
  .breadcrum-wrap {
    padding-right: 2.34375vw;
  }
}
@media (max-width: 1100px) {
  .breadcrum-wrap {
    padding-left: 15px;
    margin-top: 2.34375vw;
    margin-bottom: 2.34375vw;
  }
}
.breadcrum-wrap .breadcrumb {
  font-size: 16px;
  font-size: 1rem;
  color: #404040;
  background-color: transparent;
  margin-bottom: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item {
  color: #777;
  text-transform: uppercase;
}
.breadcrum-wrap .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "/";
  padding-left: 0.15em;
  padding-right: 0.5em;
}
@media (min-width: 1101px) {
  .breadcrum-wrap .breadcrumb {
    justify-content: flex-end;
  }
}
@media (max-width: 1100px) {
  .breadcrum-wrap .breadcrumb {
    padding-left: 0;
    padding-top: 0.3em;
    padding-bottom: 0;
  }
}
.breadcrum-wrap .icon {
  margin-top: 0px;
  margin-right: 2px;
}
.breadcrum-wrap .icon img {
  vertical-align: initial;
}

.pagination-wrap {
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.pagination-wrap .pagination {
  justify-content: center;
}
.pagination-wrap .pagination .page-link {
  width: 33px;
  height: 33px;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 33px;
  color: #6e6d6d;
  font-family: "Roboto";
  background-color: transparent;
  border-color: transparent;
  border-radius: 50%;
  text-align: center;
  padding: 0;
  margin-right: 0.5rem;
}
.pagination-wrap .pagination .page-link:hover, .pagination-wrap .pagination .page-link.active {
  color: #fff;
  background-color: #b99576;
  border-color: #b99576;
}
.pagination-wrap .pagination-select {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-wrap .pagination-select select {
  text-align: center;
  appearance: auto;
  font-size: 15px;
  font-size: 0.9375rem;
}
.pagination-wrap .pagination-select .pagination-item {
  padding-left: 20px;
}
.pagination-wrap .pagination-mobile {
  max-width: 120px;
}
@media (min-width: 1101px) {
  .pagination-wrap .pagination-mobile .pagination-select {
    width: auto;
  }
  .pagination-wrap .pagination-mobile .pagination-select select {
    max-width: 45px;
  }
}
@media (max-width: 1100px) {
  .pagination-wrap {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
}

.text-main {
  color: #004b7c !important;
}
.text-itemSub {
  color: #7d7d7d !important;
}
.text-underLine, .index-content .btn_link {
  text-decoration: underline !important;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-italic {
  font-style: italic;
}

.bg-itemSub {
  background-color: #6f6e6e !important;
}

.justify {
  text-align: justify;
}

.flex-none {
  flex: none !important;
}
@media (max-width: 640px) {
  .flex-none {
    width: 100%;
  }
}
.flex-auto {
  flex: auto !important;
}
.flex-1 {
  flex: 1 !important;
}

.tag-item, .tag-item-current, .tag-item-date {
  display: inline-block;
  width: auto;
  line-height: 1.1;
  margin: 2.5px;
  font-size: 14px;
  line-height: 1;
  padding: 6px;
  text-align: center;
  background-color: #eeeeee;
  color: #5b5a5a;
}
.tag-item-current, .tag-item-date {
  font-size: 18px;
  font-size: 1.125rem;
  color: #fff;
  padding: 8px 16px;
  border-radius: 6px;
}
.tag-item-current {
  background-color: #00b7ee;
}
.tag-item-date {
  background-color: #bfbf85;
}
.tag-item-wrap .tag-item ~ .tag-item, .tag-item-wrap .tag-item-current ~ .tag-item, .tag-item-wrap .tag-item-date ~ .tag-item, .tag-item-wrap .tag-item ~ .tag-item-current, .tag-item-wrap .tag-item-current ~ .tag-item-current, .tag-item-wrap .tag-item-date ~ .tag-item-current, .tag-item-wrap .tag-item ~ .tag-item-date, .tag-item-wrap .tag-item-current ~ .tag-item-date, .tag-item-wrap .tag-item-date ~ .tag-item-date {
  margin-left: 5px;
}
@media (min-width: 1101px) {
  .tag-item-wrap {
    padding-left: 15px;
  }
}
@media (max-width: 1100px) {
  .tag-item-wrap {
    padding-top: 10px;
  }
}

.page-mainWrapper {
  position: relative;
  padding: 15px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-image: url("../images/main-pageBg.jpg");
}
@media (min-width: 1101px) {
  .page-mainWrapper {
    padding-top: 2.1052631579vw;
    padding-bottom: 2.1052631579vw;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .page-mainWrapper {
    padding-left: 2.1052631579vw;
    padding-right: 2.1052631579vw;
  }
}

.detail-label {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
  padding: 0 6px;
  height: 20px;
  text-align: center;
  background-color: #fff;
  line-height: 20px;
  vertical-align: middle;
  font: 13px/25px Arial;
  border: 1px solid #fff;
}
.detail-label-other {
  border-color: #e9a3a8;
  color: #e9a3a8;
  line-height: 20px;
}
.detail-blockquote {
  display: block;
  background: #fff;
  padding: 15px 20px 12px 25px;
  margin: 0 0 20px;
  position: relative;
  /*Font*/
  font-size: 16px;
  /*Borders - (Optional)*/
  border-left: 10px solid #004b7c;
  /*Box Shadow - (Optional)*/
  box-shadow: 2px 2px 15px #ccc;
}

.radio_style {
  margin-top: 10px !important;
}
.radio_style input[type=radio] {
  display: none;
}
.radio_style input + label {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 1;
  padding-left: 30px;
  margin-bottom: 0;
}
.radio_style input + label::before, .radio_style input + label::after {
  content: "";
  display: inline-block;
  border-radius: 50%;
}
.radio_style input + label::before {
  width: 20px;
  height: 20px;
  border: #cecece 1px solid;
  background: #fff;
  position: absolute;
  top: -1px;
  left: 0;
}
.radio_style input + label::after {
  width: 10px;
  height: 10px;
  background-color: transparent;
  position: absolute;
  left: 5px;
  top: 4px;
}
.radio_style input:checked + label::after {
  background-color: #004b7c;
}

.form-control,
.btn {
  border-radius: 0.5em;
}

.form-control {
  height: auto;
}
.modal {
  text-align: center;
  padding-right: 0 !important;
}
.modal-content {
  border-color: #accccd;
  border-width: 3px;
}
.modal-open {
  padding-right: 0 !important;
}
@media (min-width: 1101px) {
  .modal {
    text-align: center;
    padding: 0 !important;
  }
  .modal:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}
@media (min-width: 500px) {
  .modal-dialog {
    width: 100%;
    max-width: 85rem;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .modal-dialog {
    max-width: 70rem;
  }
}
.modal-autoheight .modal-body {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100px !important;
  max-height: 100vh !important;
}
@media (min-width: 500px) {
  .modal-autoheight .modal-body {
    max-height: calc(100vh - 50px) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight .modal-body {
    height: calc(90vh - 1.5rem) !important;
  }
}
@media (max-width: 1080px) {
  .modal-autoheight {
    transform: translateY(5%);
  }
}

.btn:hover {
  background-color: #004b7c !important;
  border-color: #004b7c !important;
  color: #fff !important;
}

.btn:hover {
  background-color: #004b7c !important;
  border-color: #004b7c !important;
  color: #fff !important;
}

.btn {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 100;
  border-radius: 0 !important;
  padding: 0.35em 1.5em 0.5em;
}
@media (max-width: 1100px) {
  .btn {
    font-size: 16px;
    font-size: 1rem;
    padding-left: 1em;
    padding-right: 1em;
  }
}
.btn .icon {
  vertical-align: top;
  margin-top: 0px;
  margin-right: 3px;
}
.btn-wrap {
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  margin: 2rem 0 1rem;
}
.btn-wrap > li {
  flex: 1;
}
.btn-wrap > li + li {
  margin-left: 1.5rem;
}
@media (max-width: 1080px) {
  .btn-wrap {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .btn-wrap > li + li {
    margin-left: 0.5rem;
  }
}
.btn-skin {
  background-color: #dec9bc;
}
.btn-main {
  background-color: #004b7c;
  color: #fff;
}
.btn-secondary {
  background-color: #b99576;
}
.btn-white {
  background-color: #fff;
}
.btn-outline-dark {
  border: 1px solid #212529 !important;
}

.js-toggle-btn .btn {
  font-size: 16px;
  font-size: 1rem;
  border-radius: 20px;
  border: #eee 1px solid;
}
.js-toggle-btn .btn i {
  color: currentColor;
}
.js-toggle-btn .btn .icon {
  margin-top: 0;
}

.js-open {
  display: block !important;
  margin-top: 5px;
}

.box {
  align-items: stretch;
  position: relative;
  z-index: 1;
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px;
}
.box p {
  margin-bottom: 0;
}
.box-head {
  flex: none;
  color: #717171;
  padding-left: 10px;
}
.box-text {
  flex: 1;
}

.card {
  flex-grow: 1;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 2em;
  border: none;
  text-align: left;
  border-radius: 0;
}
@media (min-width: 1101px) {
  .card__2 {
    flex: 0 0 calc(50% - 2em);
    max-width: calc(50% - 2em);
  }
}
@media (min-width: 1101px) {
  .card__3 {
    flex: 0 0 calc(33.3333333333% - 2em);
    max-width: calc(33.3333333333% - 2em);
  }
}
@media (min-width: 1101px) {
  .card__4 {
    flex: 0 0 calc(25% - 2em);
    max-width: calc(25% - 2em);
  }
}
@media (max-width: 1100px) {
  .card {
    margin-left: 0.5em;
    margin-right: 0.5em;
    flex: 0 0 calc(50% - 1em);
    max-width: calc(50% - 1em);
  }
}
.card__full {
  flex: 0 0 calc(100% - 2em) !important;
  max-width: calc(100% - 2em) !important;
}
@media (min-width: 1101px) {
  .card__horizontal {
    flex-direction: row;
  }
}
.card-img-top {
  border-radius: 0px;
}
.card-img-top img {
  display: block;
  width: 100%;
}
.card-img-top + .card-caption {
  margin-top: 10px;
}
.card-time-date {
  font-size: 24px;
  font-size: 1.5rem;
}
@media (max-width: 1100px) {
  .card-time-date {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.card-time-year {
  font-size: 16px;
  font-size: 1rem;
  color: #8b8b8b;
}
@media (min-width: 1101px) {
  .card-time {
    margin-bottom: 1em;
  }
}
.card-title {
  font-size: 23px;
  font-size: 1.4375rem;
  font-weight: 900;
  line-height: 1.5;
}
.card-caption {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
}
.card-body {
  padding: 1.25em;
}
.card-text {
  font-size: 16px;
  font-size: 1rem;
  color: #5b5a5a;
  font-weight: 100;
  line-height: 1.3;
}
.card-btn {
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 900;
  background-color: #c9c9c9 !important;
  color: #fff !important;
  margin-bottom: 1em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
.card-btn:hover {
  background-color: #004b7c !important;
}
.card-btn-more {
  font-size: 14px;
  font-size: 0.875rem;
  border-bottom: #333 1px solid;
  padding-bottom: 2px;
}
@media (max-width: 640px) {
  .card-btn {
    padding-left: 0;
    padding-right: 0;
  }
}
.card-linkNormal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.card-wrap {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}
.card:hover .card-btn {
  color: #252525;
}
.card:hover .card-btn::after {
  background-color: #252525;
}
@media (max-width: 1100px) {
  .card {
    margin-bottom: 1em;
  }
  .card-title {
    font-size: 15px;
    font-size: 0.9375rem;
    margin-top: 0.25em;
  }
  .card-body {
    padding: 0.75em;
  }
  .card-caption {
    padding-top: 0.125em;
    padding-bottom: 0.25em;
  }
}

@media (min-width: 1101px) {
  .form-inMain-wrap {
    align-items: flex-end;
    padding-left: 70px;
    padding-right: 70px;
  }
}
.form-wrap {
  background-color: #fff;
  border: #eee 1px solid;
  padding: 1.5em;
}
.form-wrap legend {
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 2.5em;
}
.form-wrap .form-control {
  border-radius: 0;
  border-width: 0 0 1px 0px;
}
@media (min-width: 1101px) {
  .form-wrap {
    padding: 50px;
  }
}
.form-flex {
  display: flex;
  margin-bottom: 25px;
}
.form-flex label {
  flex: none;
  width: 80px;
}
.form-check {
  padding-left: 0;
}
.form-box .title {
  text-align: center;
  padding: 20px 0 15px;
  font-size: 30px;
  font-size: 1.875rem;
  color: #004b7c;
}
.form-box .section {
  padding: 20px 0;
}
.form-box .form-control {
  height: 50px;
  border-radius: 0;
}
.form-box .message {
  display: block;
  width: 100%;
  margin: 20px 0 0;
  font-size: 16px;
  text-align: right;
}
.form-box .message a {
  position: relative;
  display: inline-block;
  width: 25%;
  color: #636262;
  text-decoration: none;
  text-align: left;
  border-bottom: #ccc 1px solid;
  padding-bottom: 10px;
}
.form-box .message a::after {
  content: "→";
  display: inline-block;
  float: right;
}
.form-box.forget {
  display: none;
}
.form-box .form-list p {
  margin-bottom: 0;
}
.form-box.regis .form-group {
  display: flex;
  align-items: center;
}
.form-box.regis .form-group > label {
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}
.form-box.regis .form-group .form-list {
  flex: 1;
  display: inline-flex;
  align-items: center;
}
.form-box.regis .form-group .form-list label {
  margin-bottom: 0;
}
.form-box.regis.regis-form .form-group {
  align-items: normal;
  margin-bottom: 5px;
  border: 1px solid #dee2e6;
}
.form-box.regis.regis-form .form-group > label {
  min-width: 200px;
  background: #f0f0f0;
  padding: 10px;
}
.form-box.regis.regis-form .form-group .form-list {
  padding: 5px 10px;
}
.form-box.regis.regis-form .form-group .form-list .form-control {
  margin-bottom: 5px;
}
@media (max-width: 810px) {
  .form-box.regis.regis-form .form-group {
    flex-direction: column;
    align-items: flex-start;
  }
  .form-box.regis.regis-form .form-group > label {
    width: 100%;
    flex-direction: row !important;
    justify-content: flex-start;
    font-size: 16px;
    font-size: 1rem;
  }
  .form-box.regis.regis-form .form-group .form-list {
    width: 100%;
    flex-wrap: wrap;
  }
  .form-box.regis.regis-form .form-group .radio {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .form-box.regis.regis-form .form-group .btn {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    margin-left: 0 !important;
  }
  .form-box.regis.regis-form .form-group .form-input .code {
    width: inherit;
  }
}
.form-status {
  margin-bottom: 1.5rem;
}
.form-status-link .icon {
  margin-top: -3px;
}

.member-content {
  padding-top: 10px;
  padding-bottom: 40px;
}
.member-content .form-box {
  max-width: 650px;
  background-color: #fff;
  border-radius: 25px;
  padding: 1.5em 3em 1.5em 2em;
  overflow: hidden;
}
.member-content-intro {
  background-color: #eee;
}
.member-content-intro-wrap {
  max-width: 600px;
}
.member-content-login {
  background-color: #fff;
}
.member-content-login .form-group {
  margin-bottom: 1.5rem;
}
.member-content-login .btn-wrap {
  margin-top: 1rem;
}
@media (max-width: 1100px) {
  .member-content-login .btn-wrap {
    flex-wrap: wrap;
  }
  .member-content-login .btn-wrap > li {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media (max-width: 1100px) {
  .member-content {
    padding: 0em;
  }
}
.member-editor .form-box {
  padding: 5px 0;
  border-radius: 0;
}
.member-editor .form-group label {
  width: 150px;
}
.member-editor .form-group > label {
  padding-left: 0;
}
.member-editor .form-group-checkbox {
  flex-direction: row;
  padding-bottom: 0;
  margin-bottom: 0;
}
.member-editor .form-group-checkbox label {
  width: 100% !important;
}
.member-editor .form-group-checkbox .radio_style {
  margin-top: 0px !important;
}
.member-editor .form-group .uploader {
  min-width: 20em;
  max-width: 100%;
  flex: 1;
}
@media (min-width: 1101px) {
  .member-editor .form-group .uploader {
    max-width: 20em;
  }
}
.member-editor .form-group .uploader-btn .btn {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
@media (max-width: 1100px) {
  .member-editor .form-group .uploader-btn {
    margin-top: 1em;
  }
}
.member-editor-buildCase-form {
  background: #eceae8;
  border-radius: 30px;
  border-top-left-radius: 0;
  padding: 2em;
}
.member-editor-buildCase-form .form-box {
  flex-wrap: wrap;
  background-color: transparent;
}
.member-editor-buildCase-form .pagination-wrap {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
@media (max-width: 1100px) {
  .member-editor-buildCase-form {
    border-top-right-radius: 0;
    padding: 0.75em 1.5em;
    margin-top: 0;
  }
}
.member-editor-buildCase-tab {
  justify-content: space-between;
}
.member-editor-buildCase-tab .nav,
.member-editor-buildCase-tab .nav li {
  position: relative;
  flex: 1;
}
.member-editor-buildCase-tab .nav-link {
  position: relative;
  z-index: 0;
  display: block;
  text-align: center;
  padding: 0.75rem 0.35rem !important;
  background-color: #e3e0de;
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
}
@media (min-width: 1101px) {
  .member-editor-buildCase-tab .nav-link {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
@media (max-width: 1100px) {
  .member-editor-buildCase-tab .nav-link {
    font-size: 18px;
    font-size: 1.125rem;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
}
.member-editor-buildCase-tab .nav li:hover > a, .member-editor-buildCase-tab .nav li.active > a {
  background-color: #eceae8;
}
.member-editor-buildCase-tab .nav::before {
  content: "";
  display: block;
  width: calc(100% - 70px);
  height: 50px;
  background-color: #e3e0de;
  position: absolute;
  top: 0px;
  left: 35px;
}
@media (min-width: 1101px) {
  .member-editor-buildCase-tab .nav {
    max-width: 68%;
  }
}
.member-editor-buildCase-tab .btn-wrap .btn {
  padding-bottom: 0.35em;
}
.member-editor-buildCase-tab .btn-wrap .btn .icon {
  margin-top: 0;
}
@media (min-width: 1101px) {
  .member-editor-buildCase-tab .btn-wrap {
    flex: 0 0 200px;
    margin: 0;
  }
}
@media (max-width: 1100px) {
  .member-editor-buildCase-tab .btn-wrap {
    order: -1;
    justify-content: flex-end;
  }
}
.member-editor-buildCase .form-group {
  margin-bottom: 0.25rem;
}
.member-editor-buildCase .icon {
  margin-top: -6px;
}
@media (max-width: 1100px) {
  .member-editor-buildCase .form-group {
    width: 100%;
    flex-direction: row !important;
  }
}
.member-editor-buildCaseAdd .form-group {
  align-items: center;
}
.member-editor-buildCaseAdd .form-group > label {
  min-width: 210px;
  line-height: 1.2;
}
@media (min-width: 1101px) {
  .member-editor-buildCaseAdd .form-group > label {
    padding-left: 1.5rem;
  }
}
@media (max-width: 1100px) {
  .member-editor-buildCaseAdd .form-group {
    align-items: flex-start;
  }
  .member-editor-buildCaseAdd .form-group.flex-column {
    flex-direction: column !important;
  }
}
@media (max-width: 1100px) {
  .member-editor-buildCaseAdd .form-box {
    margin-bottom: 10px;
  }
  .member-editor-buildCaseAdd .form-box > .form-group {
    flex-direction: column !important;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .member-editor-buildCaseAdd .form-box > .form-group > label {
    padding-left: 0;
    padding-bottom: 10px;
  }
  .member-editor-buildCaseAdd .form-box > .form-group .form-input-size {
    width: 100% !important;
    flex: 1;
  }
  .member-editor-buildCaseAdd .form-box .twzipcode-style {
    width: calc(100% + 30px) !important;
  }
  .member-editor-buildCaseAdd .form-lineHref {
    margin-top: 0.75em;
    flex-direction: column;
  }
  .member-editor-buildCaseAdd .form-lineHref .form-input-size,
.member-editor-buildCaseAdd .form-lineHref .unit {
    width: 100% !important;
  }
  .member-editor-buildCaseAdd .form-lineHref .unit {
    padding-left: 0;
    padding-top: 5px;
  }
}

.empty-row {
  margin-left: auto;
  margin-right: auto;
  padding: 40px 20px;
  max-width: 640px;
}
.empty-row .img-box {
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
}
.empty-row .img-box img {
  display: block;
  width: 100%;
}
.empty-row .content {
  margin-top: 20px;
  text-align: center;
}
.empty-row .content .title {
  color: #222;
  font-size: 32px;
  font-family: "Open Sans";
  line-height: 1.2;
  margin: 0;
}
.empty-row .content .des {
  color: #999;
  font-size: 17px;
  line-height: 1.6;
  font-family: "Open Sans";
  margin: 0;
  margin-top: 10px;
}

@media (max-width: 640px) {
  .empty-row .content {
    margin-top: 30px;
  }
  .empty-row .content .title {
    font-size: 26px;
  }
  .empty-row .content .des {
    font-size: 16px;
    margin-top: 15px;
  }
}
:root {
  --fancy-slider-theme-color: #fff;
  --fancy-slider-font-family: sans-serif;
  --fancy-slider-button-side-offset: 32px;
  --fancy-slider-button-mobile-side-offset: 12px;
  --fancy-slider-button-mobile-bottom-offset: 0px;
  --fancy-slider-head-font-size-s: 36px;
  --fancy-slider-head-font-size: 48px;
  --fancy-slider-head-font-size-b: 60px;
  --fancy-slider-title-font-size: 33px;
  --fancy-slider-title-font-family: $OpenSans-300;
  --fancy-slider-title-line-height: 1;
  /* Card */
  --card-transition-duration: 500ms;
  --card-transition-delay: 250ms; /* delay background color */
  /* background */
  --fancy-slider-transition-background-duration: 1000ms; /* duration background color */
  --fancy-slider-transition-background-delay: 1000ms; /* delay background color */
  /* other */
  --fancy-slider-transition-slide-delay: 850ms; /* delay slide translation */
  --fancy-slider-transition-title-delay: 800ms; /* delay title translation */
  --fancy-slider-transition-timing-function: cubic-bezier(0.5, 0, 0, 1); /* global timing function */
  /* scaling */
  --fancy-slider-transform-scale-slide: 1; /* before scale in slide - same as in js */
  --fancy-slider-transform-scale-img: 1; /* before scale in img - same as in js */
  /* nav */
  --fancy-slider-transition-button: 400ms; /*  */
  --fancy-slider-transition-button-arrow: 400ms; /*  */
  --fancy-slider-transition-button-circle: 400ms; /*  */
  --bs-aspect-ratio-mobile: 100% ;
}

@keyframes jump {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
  100% {
    bottom: 0;
  }
}
@keyframes moveCircle {
  0%, 100% {
    transform: translateY(-15%);
  }
  50% {
    transform: translateY(-5%);
  }
}
.animationItem {
  transform: translate3d(-50px, 0, 0);
  opacity: 0;
  transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}

.animationItem.animated {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.index-content {
  padding-top: 1.5em;
  padding-bottom: 2em;
}
@media (max-width: 1400px) {
  .index-content {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media (max-width: 1100px) {
  .index-content {
    padding: 1.5em 0.5em;
  }
}
.index-content .btn, .index-content .btn_link {
  font-family: "Poppins", sans-serif;
}
@media (max-width: 1100px) {
  .index-pageWrapper {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
}
.index-pageHead {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1;
  -webkit-text-stroke: 2px #000;
}
@media (max-width: 1100px) {
  .index-pageHead {
    font-size: 35px;
    font-size: 2.1875rem;
  }
  .index-pageHead br {
    display: none;
  }
}
.index-pageArticle {
  line-height: 1.5;
}
.index-pageAbout {
  padding-top: 8.4210526316vw;
  padding-bottom: 3.1578947368vw;
}
.index-pageAbout .pageAbout-img::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  max-width: 900px;
  max-height: 310px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 900px;
  background-image: url("../images/index-pageAbout-imgLeft.jpg");
  position: absolute;
  top: -4.2105263158vw;
  left: -6.3157894737vw;
  z-index: -1;
}
@media (min-width: 1101px) {
  .index-pageAbout .pageAbout-img {
    width: 50.5263157895vw;
  }
}
@media (min-width: 1101px) {
  .index-pageAbout .pageAbout-info {
    width: calc(100% - 30px);
    padding-left: 3.1578947368vw;
    padding-right: 0.2631578947vw;
  }
}
@media (max-width: 1100px) {
  .index-pageAbout .pageGrid {
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.index-pagePro {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  background-image: url("../images/index-pageProd-bg.jpg");
}
@media (max-width: 1100px) {
  .index-pagePro {
    background-size: cover;
  }
}

.titlebox {
  display: inline-block;
  padding-right: 3.6842105263vw;
  padding-top: 3.9473684211vw;
  border-right: #262626 1px solid;
}

.page-banner {
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
@media (min-width: 900px) and (max-width: 1100px) {
  .page-banner {
    margin-top: 1%;
  }
}
@media (max-width: 1100px) {
  .page-banner.index {
    height: 46.875vw;
  }
}
@media (max-width: 640px) {
  .page-banner-main img {
    height: 100px;
    object-fit: cover;
  }
}
.page-gallerSwiper {
  overflow: hidden;
}
.page-gallerSwiper .swiper-pagination {
  width: fit-content;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
.page-gallerSwiper .swiper-pagination-bullet {
  width: 50px;
  height: 5px;
  border-radius: 0;
  margin: 0 !important;
}
.page-gallerSwiper .swiper-pagination-bullet-active {
  background-color: #004b7c;
}
.page-gallerSwiper .card-body {
  padding: 0;
}
.page-gallerSwiper .card-title {
  font-weight: 400;
  line-height: 1.15;
  word-wrap: normal;
}
.page-gallerSwiper .card-img-top {
  text-align: center;
}
.page-gallerSwiper .card-img-top img {
  display: inline-block;
}
.page-gallerSwiper .swiper-pagination {
  bottom: 0.5263157895vw !important;
}
@media (min-width: 760px) {
  .page-gallerSwiper .swiper-pagination-bullet {
    opacity: 0.2;
  }
  .page-gallerSwiper .swiper-pagination-bullet-active {
    opacity: 1;
  }
}
@media (max-width: 1100px) {
  .page-gallerSwiper .swiper-pagination {
    justify-content: center;
  }
}
.page-gallerSwiper-pro {
  width: 100%;
  padding-top: 0.7894736842vw;
  padding-bottom: 2.1052631579vw;
}
.page-gallerSwiper-pro .card-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 1101px) {
  .page-gallerSwiper-pro .card-body {
    position: absolute;
    padding: 3em;
    color: #fff;
    background-color: rgba(49, 49, 49, 0.85);
  }
}
.page-gallerSwiper-pro .card-title {
  font-size: 42px;
  font-size: 2.625rem;
  font-family: var(--fancy-slider-title-font-family);
  border-bottom: #262626 1px solid;
  text-align: center;
  padding-bottom: 0.3em;
  margin-bottom: 0.35em;
}
@media (min-width: 1101px) {
  .page-gallerSwiper-pro .card-title {
    border-bottom-color: rgba(255, 255, 255, 0.65);
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1101px) {
  .page-gallerSwiper-pro .card-linkNormal ~ .card-body {
    opacity: 0;
    transition: var(--card-transition-duration);
  }
  .page-gallerSwiper-pro .card-linkNormal ~ .card-body .card-title,
.page-gallerSwiper-pro .card-linkNormal ~ .card-body .card-des {
    transition-duration: var(--card-transition-duration);
    transition-delay: var(--card-transition-delay);
  }
  .page-gallerSwiper-pro .card-linkNormal ~ .card-body .card-title {
    transform: translateY(-1000%);
  }
  .page-gallerSwiper-pro .card-linkNormal ~ .card-body .card-des {
    transform: translateY(1000%);
  }
  .page-gallerSwiper-pro .card-linkNormal:hover ~ .card-body {
    opacity: 1;
  }
  .page-gallerSwiper-pro .card-linkNormal:hover ~ .card-body .card-title,
.page-gallerSwiper-pro .card-linkNormal:hover ~ .card-body .card-des {
    transform: translateY(0);
  }
}
@media (max-width: 810px) {
  .page-gallerSwiper-pro .card {
    flex-direction: row;
    align-items: flex-start;
    background-color: transparent;
  }
  .page-gallerSwiper-pro .card-img-top {
    border-radius: 20px;
    overflow: hidden;
    flex-basis: 150px;
    margin-top: 46px;
  }
  .page-gallerSwiper-pro .card-body {
    padding-left: 1.25em;
    padding-right: 0.25em;
    flex: 0 0 calc(100% - 150px);
  }
  .page-gallerSwiper-pro .card-title {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}
.page-gallerSwiper-pro .swiper-slide .card {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 1101px) {
  .page-gallerSwiper-pro .swiper-slide-active .card, .page-gallerSwiper-pro .swiper-slide-next .card {
    border-bottom-right-radius: 0 !important;
  }
  .page-gallerSwiper-pro .swiper-slide-active .card {
    border-top-left-radius: 40%;
  }
  .page-gallerSwiper-pro .swiper-slide-next .card {
    border-radius: 20px;
  }
  .page-gallerSwiper-pro .swiper-slide .card {
    border-bottom-right-radius: 40%;
    overflow: hidden;
  }
}
.page-gallerSwiper-pro .swiper-act-container {
  align-items: flex-end;
  justify-content: end;
  margin-bottom: 2.75em;
  padding-right: 1em;
}
.page-gallerSwiper-pro .swiper-act-container .swiper-pagination {
  position: relative;
  width: auto;
  top: 0px;
}
.page-gallerSwiper-pro .swiper-act-container .swiper-pagination::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #cbcbcb;
  position: absolute;
  top: 25px;
  z-index: -1;
}
.page-gallerSwiper-pro .swiper-act-button {
  width: calc(3.5em + 10px);
  padding-left: 10px;
  justify-content: space-between;
}
.page-gallerSwiper-pro .swiper-act-button .swiper-button-next,
.page-gallerSwiper-pro .swiper-act-button .swiper-button-prev {
  position: static;
  color: #262626;
  height: auto;
  margin-top: 0;
}
.page-gallerSwiper-pro .swiper-act-button .swiper-button-next::after,
.page-gallerSwiper-pro .swiper-act-button .swiper-button-prev::after {
  font-size: 14px;
}
.page-gallerSwiper-pro .swiper-pagination-fraction span {
  color: #b9b9b9;
  font-size: 15px;
  font-family: "Open Sans";
  padding: 0 2px;
}
.page-gallerSwiper-pro .swiper-pagination-fraction .swiper-pagination-empty {
  width: 30px;
}
.page-gallerSwiper-pro .swiper-pagination-fraction .swiper-pagination-current {
  position: relative;
  color: #262626;
  font-weight: 600;
}
.page-gallerSwiper-pro .swiper-pagination-fraction .swiper-pagination-current::after {
  content: "";
  display: block;
  width: 30px;
  height: 3px;
  background-color: #004b7c;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 1100px) {
  .page-gallerSwiper-pro {
    display: flex;
    flex-direction: column;
  }
  .page-gallerSwiper-pro .swiper-act-container {
    order: 1;
    margin-top: 1.1em;
  }
}
.pageGrid {
  display: grid;
  grid-column-gap: 0;
  grid-auto-rows: 0fr 0fr 0fr;
}
.pageGrid-list-frAuto {
  grid-template-columns: repeat(1, auto) 1fr;
}
.pageGrid-list-1to1 {
  grid-template-columns: 1fr 1fr;
}
.pageGrid-list-2to1 {
  grid-template-columns: 2fr 1fr 1fr;
}
.pageGrid-list-avg {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.pageGrid-list-1to2 {
  grid-template-columns: 1fr 1fr 2fr;
}
@media (max-width: 1080px) {
  .pageGrid {
    grid-template-columns: auto !important;
  }
}
/* swiper index banner */
.swiper-bannerIn {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* Arrows */
}
.swiper-bannerIn .swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-bannerIn .swiper-image {
  height: 100%;
}
.swiper-bannerIn .swiper-image-inner {
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-blend-mode: overlay;
}
.swiper-bannerIn .swiper-slide {
  transition: all 1s linear;
  transition-delay: 0.5s;
  opacity: 0;
  overflow: hidden;
}
.swiper-bannerIn .swiper-slide.swiper-slide-next {
  opacity: 0;
}
.swiper-bannerIn .swiper-slide,
.swiper-bannerIn .swiper-slide.swiper-slide-active + .swiper-slide-next,
.swiper-bannerIn .swiper-slide.swiper-slide-duplicate-active {
  transform: translateX(100%) translateZ(0);
}
.swiper-bannerIn .swiper-slide.swiper-slide-duplicate,
.swiper-bannerIn .swiper-slide.swiper-slide-duplicate-next,
.swiper-bannerIn .swiper-slide.swiper-slide-duplicate-prev {
  transform: translateX(-25%) translateZ(0);
}
.swiper-bannerIn .swiper-slide.swiper-slide-next,
.swiper-bannerIn .swiper-slide.swiper-slide-prev {
  opacity: 0.9;
  transform: translateX(25%) translateZ(0);
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .swiper-bannerIn .swiper-slide.swiper-slide-next,
.swiper-bannerIn .swiper-slide.swiper-slide-prev {
    transform: translateX(20%) translateZ(0);
  }
}
.swiper-bannerIn .swiper-slide.swiper-slide-next .swiper-image,
.swiper-bannerIn .swiper-slide.swiper-slide-prev .swiper-image {
  border-top-right-radius: 25em;
  overflow: hidden;
}
.swiper-bannerIn .swiper-slide.swiper-slide-next .swiper-image::after,
.swiper-bannerIn .swiper-slide.swiper-slide-prev .swiper-image::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1;
}
@media (max-width: 1100px) {
  .swiper-bannerIn .swiper-slide.swiper-slide-next .swiper-image,
.swiper-bannerIn .swiper-slide.swiper-slide-prev .swiper-image {
    border-top-right-radius: 4.5em;
  }
}
.swiper-bannerIn .swiper-slide.swiper-slide-active {
  opacity: 1;
  width: 100% !important;
  max-width: 1550px !important;
  transform: translateX(24%) translateZ(0);
  overflow: visible;
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .swiper-bannerIn .swiper-slide.swiper-slide-active {
    transform: translateX(15%) translateZ(0);
  }
}
@media (max-width: 1100px) {
  .swiper-bannerIn .swiper-slide.swiper-slide-active {
    transform: translateX(20%) translateZ(0);
  }
}
.swiper-bannerIn .swiper-slide.swiper-slide-active .swiper-image {
  border-top-left-radius: 25em;
  overflow: hidden;
}
@media (max-width: 1100px) {
  .swiper-bannerIn .swiper-slide.swiper-slide-active .swiper-image {
    border-top-left-radius: 4.5em;
  }
}
.swiper-bannerIn .swiper-text {
  position: absolute;
  left: -12%;
  bottom: 10%;
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .swiper-bannerIn .swiper-text {
    left: -10%;
  }
}
@media (max-width: 1100px) {
  .swiper-bannerIn .swiper-text {
    bottom: 0;
  }
}
.swiper-bannerIn .swiper-image.swiper-image-active img {
  width: 100%;
  height: 730px;
  object-fit: cover;
}
@media (min-width: 1100px) and (max-width: 1650px) {
  .swiper-bannerIn .swiper-image.swiper-image-active img {
    height: 40em;
  }
}
.swiper-bannerIn .swiper-image-inner.swiper-image-active {
  transition: all 1s linear;
  transition-delay: 1s;
  background-blend-mode: multiply;
}
.swiper-bannerIn h1 {
  align-self: flex-start;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 5.46875vw;
  line-height: var(--fancy-slider-title-line-height);
  font-family: var(--fancy-slider-title-font-family);
  letter-spacing: -1px;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1) 2s;
  transform: translate3d(-20%, 0, 0);
  opacity: 0;
}
@media (min-width: 1101px) {
  .swiper-bannerIn h1 {
    font-size: 3.0526315789vw;
  }
}
@media (max-width: 1100px) {
  .swiper-bannerIn h1 {
    color: rgb(255, 255, 255) !important;
  }
}
.swiper-bannerIn h1 + p {
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  font-size: 2.5vw;
  margin-top: 0.5em;
  color: #fff;
  transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 2.3s;
  transform: translate3d(-20%, 0, 0);
  opacity: 0;
}
@media (min-width: 1101px) {
  .swiper-bannerIn h1 + p {
    font-size: 0.8947368421vw;
    margin-top: 15px;
  }
}
.swiper-bannerIn .swiper-slide.swiper-slide-active h1,
.swiper-bannerIn .swiper-slide.swiper-slide-active h1 + p {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.swiper-bannerIn .swiper-button-prev,
.swiper-bannerIn .swiper-button-next {
  width: 140px;
  transition: var(--fancy-slider-transition-button);
  outline: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}
.swiper-bannerIn .swiper-button-prev::after,
.swiper-bannerIn .swiper-button-next::after {
  display: none;
}
@media (max-width: 1100px) {
  .swiper-bannerIn .swiper-button-prev,
.swiper-bannerIn .swiper-button-next {
    width: 100px;
  }
}
.swiper-bannerIn .swiper-button-prev svg,
.swiper-bannerIn .swiper-button-next svg {
  display: block;
  transition: var(--fancy-slider-transition-button);
}
.swiper-bannerIn .swiper-button-prev .fancy-slider-svg-circle-wrap,
.swiper-bannerIn .swiper-button-next .fancy-slider-svg-circle-wrap {
  transition: var(--fancy-slider-transition-button-circle);
  transform-origin: -20px 40px;
  opacity: 1;
}
.swiper-bannerIn .swiper-button-prev circle,
.swiper-bannerIn .swiper-button-next circle {
  transition: var(--fancy-slider-transition-button-circle);
  stroke-width: 2px;
  stroke: #fff;
  fill: none;
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  opacity: 1;
  transform-origin: 0px 0px 0px;
}
.swiper-bannerIn .swiper-button-prev .fancy-slider-svg-arrow,
.swiper-bannerIn .swiper-button-next .fancy-slider-svg-arrow {
  transition: var(--fancy-slider-transition-button-arrow);
  fill: #fff;
  transform: rotateY(180deg) translate(-49px, 31.1px) scale(3);
}
.swiper-bannerIn .swiper-button-prev .fancy-slider-svg-wrap,
.swiper-bannerIn .swiper-button-next .fancy-slider-svg-wrap {
  transform: translateY(353px);
}
.swiper-bannerIn .swiper-button-prev .fancy-slider-svg-line,
.swiper-bannerIn .swiper-button-next .fancy-slider-svg-line {
  transition: var(--fancy-slider-transition-button);
  stroke: #fff;
  stroke-width: 2;
  transform: translate(50px, 42px);
}
.swiper-bannerIn .swiper-button-prev:not(.fancy-slider-button-disabled):hover circle,
.swiper-bannerIn .swiper-button-next:not(.fancy-slider-button-disabled):hover circle {
  stroke-dasharray: 4px;
  stroke-dashoffset: 4px;
  opacity: 1;
}
.swiper-bannerIn .swiper-button-next .fancy-slider-svg-wrap {
  transform: translateY(353px) rotateY(180deg);
  transform-origin: 80px 0px 0px;
}
.swiper-bannerIn .swiper-pagination {
  width: 100%;
  position: absolute;
  text-align: center;
  transition: 300ms;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-bannerIn .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  background: #fff;
  opacity: 0.4;
}
.swiper-bannerIn .swiper-pagination-bullet-active {
  opacity: 1;
  background: #fff;
}

.pageAbout {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  background-image: url("../images/main-pageAbout-boxBg.jpg");
  background-color: #fff;
  padding: 3.4210526316vw;
  z-index: 1;
}
@media (max-width: 1100px) {
  .pageAbout {
    margin-bottom: 2em;
  }
}
.pageAbout::before, .pageAbout::after {
  content: "";
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  z-index: -1;
}
@media (max-width: 1100px) {
  .pageAbout::before, .pageAbout::after {
    background-size: 100%;
  }
}
.pageAbout::before {
  max-width: 40px;
  max-height: 521px;
  background-image: url("../images/main-pageAbout-boxShadow-right.png");
  right: -2.5%;
  top: 0;
}
@media (max-width: 1100px) {
  .pageAbout::before {
    display: none;
  }
}
.pageAbout::after {
  content: "";
  max-width: 564px;
  max-height: 40px;
  background-image: url("../images/main-pageAbout-boxShadow-bottom.png");
  left: 0;
  bottom: -5%;
}
@media (max-width: 1100px) {
  .pageAbout::after {
    bottom: -2.5%;
  }
}
.pageAbout-img .img-thumbnail {
  background-color: transparent;
  border-radius: 0;
  text-align: center;
}
@media (min-width: 1101px) {
  .pageAbout-img {
    margin-top: 1vw;
  }
  .pageAbout-img .img-thumbnail {
    padding: 0;
    margin-bottom: 0;
  }
}
.pageAbout-info {
  padding-left: 15px;
  padding-right: 15px;
}
.pageAbout-info-title {
  width: 100%;
}
.pageAbout-info-title p {
  margin: 0;
}
@media (min-width: 1101px) {
  .pageAbout-info-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    writing-mode: vertical-lr;
  }
  .pageAbout-info-title hr {
    border: none;
    width: 1px;
    height: 95%;
    background: #707070;
    opacity: 1;
    margin: 0 10px;
  }
}
.pageAbout-info-name {
  font-size: 36px;
  font-size: 2.25rem;
  letter-spacing: 6px;
}
.pageAbout-info-subname {
  font-size: 18px;
  font-size: 1.125rem;
  color: #7d7d7d;
  letter-spacing: 2px;
}
.pageAbout-info-article {
  font-size: 17px;
  font-size: 1.0625rem;
  padding-top: 2.6315789474vw;
  padding-bottom: 1.5789473684vw;
}
.pageAbout-info .btn {
  display: flex;
  align-items: center;
  background-color: transparent !important;
  border: none !important;
}
.pageAbout-info .btn .icon {
  transition: var(--card-transition-duration);
}
.pageAbout-info .btn:hover {
  color: #004b7c !important;
}
.pageAbout-info .btn:hover .icon {
  transform: translateX(10px);
}
@media (max-width: 1100px) {
  .pageAbout-info .btn-wrap {
    margin-bottom: 1rem;
  }
}
@media (min-width: 1101px) {
  .pageAbout-info {
    padding-left: 3.6842105263vw;
  }
}

.pro-in-content {
  background-color: #f1f0f0;
  padding: 1rem;
}
@media (min-width: 1101px) {
  .pro-in-content {
    padding-top: 3.1578947368vw;
    padding-bottom: 3.1578947368vw;
    margin-left: -1em;
    margin-right: -1em;
  }
}
@media (max-width: 380px) {
  .pro-in-content {
    padding: 0.5rem;
  }
}
@media (min-width: 1101px) {
  .pro-card-wrap {
    margin-top: 2.5em;
  }
}
.pro-card-wrap .card {
  background-color: transparent;
}
.pro-card-wrap .card-title {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Open Sans";
  color: #004b7c;
  margin-bottom: 0.2rem;
}
@media (max-width: 1100px) {
  .pro-card-wrap .card-title {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.pro-card-wrap .card-body {
  padding: 0.75em 0.25em;
}
@media (max-width: 1100px) {
  .pro-card-wrap .card-body {
    padding-top: 0.5em;
  }
}
.pro-card-wrap .card-caption {
  font-size: 21px;
  font-size: 1.3125rem;
  color: #262626;
  padding: 0;
}
@media (max-width: 1100px) {
  .pro-card-wrap .card-caption {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}
.pro-card-wrap .card-img-top {
  background-color: #fff;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2) inset;
  overflow: hidden;
  height: 0;
  padding-bottom: 100%;
}
.pro-card-wrap .card-img-top img {
  width: auto;
  transition: 0.25s;
}
@media (min-width: 1101px) {
  .pro-card-wrap .card-linkNormal:hover ~ .card-img-top img {
    transform: scale(1.25);
  }
}
.pro-detail-blockquote {
  box-shadow: none;
}
@media (min-width: 1101px) {
  .pro-detail-blockquote {
    margin-left: 1em;
  }
}
.pro-search-listName {
  min-width: 100px;
  padding-right: 20px;
  font-family: "Open Sans";
}
.pro-search-listName::before {
  width: 6px;
  height: 6px;
  background-color: #004b7c;
  border: none;
  top: 12px;
}
@media (min-width: 1101px) {
  .pro-search-listName {
    font-size: 19px;
    font-size: 1.1875rem;
  }
}
.pro-search-listForm {
  flex: 1;
  margin-bottom: 1rem;
}
.pro-search-list .form-control {
  height: 43px;
}
.pro-search-list select {
  appearance: auto;
}
@media (min-width: 1101px) {
  .pro-search-list {
    margin-bottom: 0.75rem;
  }
}
.pro-search-list .form-control,
.pro-search-list .btn {
  border-radius: 2px !important;
}
.pro-search-wrap .form-control,
.pro-search-wrap .btn {
  font-family: "Open Sans";
}
.pro-search-wrap .btn {
  padding-left: 50px;
  padding-right: 50px;
}
.pro-search-wrap .btn-wrap {
  padding-left: 13px;
}
@media (min-width: 1101px) {
  .pro-search-wrap .btn-wrap {
    margin-top: 1.25rem;
  }
}
@media (max-width: 1100px) {
  .pro-search-wrap .btn {
    width: 100%;
  }
}
.pro-searchInfo-wrap {
  background-color: #fff;
  border: #dcdcdc 1px solid;
  padding: 20px 10px;
  margin: 26px 13px 6.5px;
}
@media (max-width: 1100px) {
  .pro-searchInfo-wrap {
    padding: 0.5em;
  }
}
.pro-searchTable {
  margin-bottom: 0;
}
.pro-searchTable th,
.pro-searchTable td {
  text-align: center;
  border-bottom: none;
}
.pro-searchTable th {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../images/table-th-bg.jpg");
}
.pro-searchTable td {
  padding-top: 0.75rem;
  padding-bottom: 0;
}
.pro-searchData-box {
  height: 250px;
  margin: 0.5em 1em;
}
@media (max-width: 1100px) {
  .pro-searchData-box {
    width: 40em;
    height: 100%;
    margin: 1em 0.5em;
  }
}
.pro-searchData-txt {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
}
.pro-searchData-txt p {
  margin-bottom: 0.1rem;
}

#my-scrollbar {
  width: 100%;
  overflow: auto;
}

.scrollbar-track-x,
.scrollbar-track-y {
  display: none;
}

.scrollbar-track-y {
  background: #d2d2d2 !important;
  border-radius: 4px !important;
}

.scrollbar-thumb {
  top: 2px !important;
  background: linear-gradient(to bottom, #ffffff 4%, #e6e6e6 100%) !important;
}
@media (min-width: 1101px) {
  .scrollbar-thumb {
    width: 6px !important;
    left: 1px !important;
  }
}
@media (max-width: 1100px) {
  .scrollbar-thumb {
    height: 6px !important;
  }
}

.pro-heading-des {
  border-bottom: 1px solid #676767;
  margin-top: 20px;
  margin-bottom: 45px;
}
.pro-heading-img {
  margin-bottom: 20px;
}
.pro-heading-txt {
  color: #464545;
  font-size: 18px;
  font-size: 1.125rem;
  padding: 10px 10px 15px;
}

@media (min-width: 1101px) {
  .Prod-conInfo {
    display: flex;
    align-items: flex-start;
    margin-top: 3.5em;
  }
}
.Prod-conInfo-img {
  overflow: hidden;
}
@media (min-width: 1101px) {
  .Prod-conInfo-img {
    flex-basis: 650px;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .Prod-conInfo-img {
    flex-basis: 500px;
  }
}
@media (min-width: 1100px) and (max-width: 1199px) {
  .Prod-conInfo-img {
    flex-basis: 400px;
  }
}
@media (max-width: 1100px) {
  .Prod-conInfo-img {
    padding-top: 0.5em;
  }
}
.Prod-conInfo-img .swiper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.Prod-conInfo-img .swiper-slide {
  border: #ededed 1px solid;
  background-color: #fff;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2) inset;
  overflow: hidden;
}
.Prod-conInfo-img .swiper-slide img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 100%;
  margin: auto;
}
.Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
  color: #000;
  top: auto;
  bottom: -95px;
}
.Prod-conInfo-img .swiper-button-next::after, .Prod-conInfo-img .swiper-button-prev::after {
  font-size: 20px;
  font-size: 1.25rem;
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
    bottom: -90px;
  }
}
@media (min-width: 1100px) and (max-width: 1199px) {
  .Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
    bottom: -7.3684210526vw;
  }
}
@media (max-width: 810px) {
  .Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
    bottom: -6.5em;
  }
}
@media (max-width: 640px) {
  .Prod-conInfo-img .swiper-button-next, .Prod-conInfo-img .swiper-button-prev {
    bottom: -4.5em;
  }
}
@media (max-width: 1100px) {
  .Prod-conInfo-img .swiper-button-next {
    right: -1%;
  }
  .Prod-conInfo-img .swiper-button-prev {
    left: -1%;
  }
}
.Prod-conInfo-img .Prod_mySwiper2 {
  height: 80%;
  width: 100%;
  margin-bottom: 0.25em;
}
.Prod-conInfo-img .Prod_mySwiper2 .swiper-slide {
  padding-bottom: 100%;
}
@media (min-width: 1101px) {
  .Prod-conInfo-img .Prod_mySwiper2 {
    margin-bottom: 15px;
  }
}
.Prod-conInfo-img .Prod_mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.Prod-conInfo-img .Prod_mySwiper .swiper-slide {
  padding-bottom: 17%;
  opacity: 0.4;
}
.Prod-conInfo-img .Prod_mySwiper .swiper-slide img {
  max-width: 35px;
}
.Prod-conInfo-img .Prod_mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .Prod-conInfo-img .Prod_mySwiper .swiper-slide {
    padding-bottom: 20%;
  }
}
@media (min-width: 1100px) and (max-width: 1199px) {
  .Prod-conInfo-img .Prod_mySwiper .swiper-slide {
    padding-bottom: 25%;
  }
}
@media (max-width: 640px) {
  .Prod-conInfo-img .Prod_mySwiper .swiper-slide {
    padding-bottom: 23%;
  }
}
.Prod-conInfo-info {
  padding-top: 0.5em;
}
@media (min-width: 1101px) {
  .Prod-conInfo-info {
    flex: 1;
    padding-left: 3.5em;
  }
}
.Prod-heading {
  display: flex;
  line-height: 1.5;
  padding-bottom: 0.25em;
  margin-bottom: 0;
}
.Prod-headingTitle {
  font-size: 25px;
  font-size: 1.5625rem;
  margin-bottom: 20px;
}
.Prod-headingTitle-num {
  font-family: "Open Sans";
  color: #004b7c;
  padding-right: 25px;
}
@media (min-width: 1101px) {
  .Prod-headingTitle {
    font-size: 30px;
    font-size: 1.875rem;
  }
}
.Prod-headingTxt, .Prod-headingSubTitle {
  font-size: 16px;
  font-size: 1rem;
}
@media (min-width: 1101px) {
  .Prod-headingTxt, .Prod-headingSubTitle {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.Prod-headingTxt {
  position: relative;
  padding-left: 20px;
}
.Prod-headingTxt::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: #b99576;
  position: absolute;
  left: 0;
  top: 8px;
  transform: rotate(45deg);
}
@media (max-width: 1100px) {
  .Prod-headingTxt {
    min-width: 150px;
  }
}
.Prod-headingSubTitle a:hover {
  color: #004b7c;
}
.Prod-headingSubTitle .icon {
  margin-top: -1px;
  margin-right: 2px;
  vertical-align: top;
}
.Prod-conTxt {
  margin-bottom: 1.5em;
}
.Prod-conSpec {
  text-align: center;
}
.Prod-conSpec-item {
  flex-shrink: 1;
  margin-bottom: 15px;
}
.Prod-conSpec-itemImg img {
  max-width: 40px;
}
.Prod-conSpec-itemNumber {
  border-bottom: #cecece 1px solid;
}
.Prod-conSpec-itemNumber_count {
  color: #626262;
}
.Prod-conSpec-item div {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
  padding-bottom: 2px;
}
.Prod-photo-small {
  padding-left: 1.5em;
  padding-right: 1.5em;
}
@media (min-width: 1101px) {
  .Prod-photo-small {
    padding-left: 3em;
    padding-right: 3em;
    margin-top: 15px;
  }
}
.Prod-socialIcons {
  display: flex;
  margin-top: 30px;
}
.Prod-socialIcons li {
  margin-right: 10px;
}
.Prod-unreset {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.Prod-unreset .nav-tabs {
  border-bottom-color: #bfbfbf;
}
.Prod-unreset .nav-item {
  min-height: auto;
}
.Prod-unreset .nav-item::before {
  display: none !important;
}
.Prod-unreset .nav-link {
  position: relative;
  font-size: 16px;
  font-size: 1rem;
  background-color: transparent !important;
  border: none;
  padding-right: 30px !important;
  padding-left: 30px !important;
}
.Prod-unreset .nav-link.active {
  color: #004b7c !important;
}
.Prod-unreset .nav-link.active::after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background-color: #004b7c;
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 1101px) {
  .Prod-unreset .nav-link {
    font-size: 19px;
    font-size: 1.1875rem;
  }
}
.Prod-unreset .tab-content {
  padding: 1em;
}
@media (min-width: 1101px) {
  .Prod-unreset .tab-content {
    padding: 2.5em 2em;
  }
}

.contact-bg {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: auto;
  background-image: url("../images/contact_bgInfo.png");
}
@media (min-width: 1101px) {
  .contact-bg {
    padding-bottom: 120px;
  }
}
@media (min-width: 1251px) and (max-width: 1450px) {
  .contact-bg {
    padding-bottom: 140px;
  }
}
@media (max-width: 1080px) {
  .contact-bg {
    background-size: 100%;
    padding-bottom: 0;
  }
}
.contact-wrapper {
  position: relative;
  padding-top: 1em;
  padding-bottom: 1.5em;
}
.contact-wrapper .btn-wrap {
  flex-wrap: wrap;
}
.contact-wrapper .btn-wrap .btn {
  font-size: 18px;
  font-size: 1.125rem;
  border-radius: 25px !important;
  padding: 5px 2rem;
}
@media (min-width: 1100px) and (max-width: 1400px) {
  .contact-wrapper .btn-wrap .btn {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (min-width: 1101px) {
  .contact-wrapper .btn-wrap {
    justify-content: space-between;
  }
  .contact-wrapper .btn-wrap li {
    flex: none;
    margin-left: 0 !important;
  }
  .contact-wrapper .btn-wrap li.contact-btn-option {
    flex: 1;
  }
  .contact-wrapper .btn-wrap li.contact-btn-option .btn-wrap {
    justify-content: flex-end;
  }
}
@media (max-width: 1100px) {
  .contact-wrapper .btn-wrap li {
    text-align: center;
    margin: 0 5px !important;
  }
  .contact-wrapper .btn-wrap .btn {
    width: 100%;
  }
}
.contact-wrapper .pro-search-list {
  flex-direction: column;
  align-items: flex-start;
}
.contact-wrapper .pro-search-listName {
  font-family: "Microsoft JhengHei", "微軟正黑體";
  margin-bottom: 0.5rem;
}
.contact-wrapper .pro-search-listForm {
  width: 100%;
}
.contact-wrapper .pro-search-list textarea {
  height: auto;
}
@media (max-width: 1100px) {
  .contact-wrapper .pro-search-list {
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 1101px) {
  .contact-wrapper {
    padding-top: 3em;
  }
  .contact-wrapper .radio_style {
    margin-top: 0 !important;
  }
}
@media (min-width: 1301px) and (max-width: 1600px) {
  .contact-map {
    max-width: 35em;
  }
}
@media (min-width: 1100px) and (max-width: 1300px) {
  .contact-map {
    max-width: 30em;
  }
}
.contact-form {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 1101px) {
  .contact-form {
    flex: 1;
    padding-top: 60px;
    padding-left: 50px;
  }
}
@media (min-width: 1101px) {
  .contact-form-flex, .contact-form-flex-list {
    align-items: center;
  }
}
.contact-btn-wrap {
  justify-content: space-between;
  padding-top: 0;
}
.contact-btn-option .btn-wrap li ~ li {
  margin-left: 5px !important;
}
@media (min-width: 1101px) {
  .contact-btn-option .btn-wrap li ~ li {
    margin-left: 1.5em !important;
  }
}