.empty-row{
    margin-left: auto;
    margin-right: auto;
    padding: 40px 20px;
    max-width: 640px;
    .img-box{
        max-width: 100px;
        margin-left: auto;
        margin-right: auto;
        img{
            display: block;
            width: 100%;
        }
    }
    .content{
        margin-top: 20px;
        text-align: center;
        .title{
            color: #222;
            font-size: 32px;
            font-family: $OpenSans-300;
            line-height: 1.2;
            margin: 0;
        }
        .des{
            color: #999;
            font-size: 17px;
            line-height: 1.6;
            font-family: $OpenSans-300;
            margin: 0;
            margin-top: 10px;
        }
    }
    // .btn-row{
    //     margin-top: 40px;
    //     .link{
    //         background: #aaa;
    //         color: #fff;
    //         display: block;
    //         font-size: 17px;
    //         margin-left: auto;
    //         margin-right: auto;
    //         padding: 13px;
    //         text-align: center;
    //         text-decoration: inherit;
    //         transition: all .4s;
    //         width: 120px;
    //         &:hover{
    //             background: #777;
    //         }
    //     }
    // }
}

@media (max-width:640px){
    .empty-row{
        .content{
            margin-top: 30px;
            .title{
                font-size: 26px;
            }
            .des{
                font-size: 16px;
                margin-top: 15px;
            }
        }
        // .btn-row{
        //     margin-top: 30px;
        // }
    }
}