// for Index swiper effect
$color_1: #fff0;
$font-family_1: var(--fancy-slider-font-family);

:root {
    --fancy-slider-theme-color: #fff;
    --fancy-slider-font-family: sans-serif;
    
    --fancy-slider-button-side-offset: 32px;
    --fancy-slider-button-mobile-side-offset: 12px;
    --fancy-slider-button-mobile-bottom-offset: 0px;

    --fancy-slider-head-font-size-s: 36px;
    --fancy-slider-head-font-size: 48px;
    --fancy-slider-head-font-size-b: 60px;
    --fancy-slider-title-font-size: 33px;
    
    --fancy-slider-title-font-family: $OpenSans-300;
    --fancy-slider-title-line-height: 1;

    /* Card */
    --card-transition-duration: 500ms;
    --card-transition-delay: 250ms;    /* delay background color */
    
    /* background */
    --fancy-slider-transition-background-duration: 1000ms; /* duration background color */
    --fancy-slider-transition-background-delay: 1000ms;    /* delay background color */
    
    /* other */
    --fancy-slider-transition-slide-delay: 850ms; /* delay slide translation */
    --fancy-slider-transition-title-delay: 800ms; /* delay title translation */
    --fancy-slider-transition-timing-function: cubic-bezier(0.5, 0, 0, 1); /* global timing function */
    
    /* scaling */
    --fancy-slider-transform-scale-slide: 1;               /* before scale in slide - same as in js */
    --fancy-slider-transform-scale-img: 1;               /* before scale in img - same as in js */
    
    /* nav */
    --fancy-slider-transition-button: 400ms;        /*  */
    --fancy-slider-transition-button-arrow: 400ms;  /*  */
    --fancy-slider-transition-button-circle: 400ms; /*  */

    // Video
    --bs-aspect-ratio-mobile: 100%
}
    
@keyframes jump {
    0% {
        bottom: 0;
    }
    50% {
        bottom: 10px;
    }
    100% {
        bottom: 0;
    }
}

@keyframes moveCircle {
    0%,
    100% {
        transform: translateY(-15%);
    }
    50% {
        transform: translateY(-5%);
    }
}
  
.animationItem {
    transform: translate3d(-50px, 0, 0);
    opacity: 0;
    transition: 0.85s cubic-bezier(0.31, 1.26, 0.19, 1.11);
}
  
.animationItem.animated {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.index{
    // 內框
    &-content{
        padding-top: 1.5em;
        padding-bottom: 2em;

        @include setStyleRwd(1400px){
            padding-left: 1em;
            padding-right: 1em;
        }

        @include setMaxRwd{
            padding: 1.5em 0.5em;
        }

        .btn{
            &, 
            &_link{
              font-family: $Poppins;
            }

            &_link{
                @extend .text-underLine
            }
        }
    }

    // 頁面使用
    &-page{
        &Wrapper{
            @extend %setFlex;
            @include setMaxRwd{
                width: 100%;
                flex-direction: column;
                justify-content: center;
            }
        }

        // 標題用
        &Head{
            @include fontSize(48px);
            @extend .fw-900;
            line-height: 1;
            -webkit-text-stroke: 2px #000;
            @include setMaxRwd{
                @include fontSize(35px);
                br{
                    display: none;
                }
            }
        }

        // 內文用
        &Article{
            line-height: 1.5;
        }

        &About{
            padding-top: get-vw(160px);
            padding-bottom: get-vw(60px);

            .pageAbout-img{
                &::after{
                    content: '';
                    display: block;
                    @include setSize(100%,100%);
                    @include setSizeMax(900px,310px);
                    @include setBackSet(top,900px);
                    background-image: url('../images/index-pageAbout-imgLeft.jpg');
                    position: absolute;
                    top: get-vw(-80px);
                    left: get-vw(-120px);
                    z-index: -1;
                }
                
                @include setMinRwd{
                    width: get-vw(960px);
                }
            }

            .pageAbout-info{
                @include setMinRwd{
                    width: calc(100% - 30px);
                    padding-left: get-vw(60px);
                    padding-right: get-vw(5px);
                }
            }

            @include setMaxRwd{
                .pageGrid{
                    display: block;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }

        &Pro{
            @include setBackSet(bottom,100%);
            background-image: url('../images/index-pageProd-bg.jpg');
            @include setMaxRwd{
                background-size: cover;
            }
        }
    }
}

.titlebox{
    display: inline-block;
    padding-right: get-vw(70px);
    padding-top: get-vw(75px);
    border-right: $colorBase 1px solid;
}


.page{
    // 共用Banner形象圖
    &-banner{
        width: 100%;
        overflow: hidden;
        z-index: 1;

        @include setWidthRwd(900px,1100px){
            margin-top: 1%;
        }

        &.index{
            @include setMaxRwd{
               height: get-vw-mobile(300px);
            }
        }

        &-main{
            @include mobile{
                img{
                    height: 100px;
                    object-fit: cover;
                }
            }
        }
    }
    
    // 共用Swiper
    &-gallerSwiper{
        overflow: hidden;
        // swiper 樣式
        .swiper{
            &-pagination{
                width: fit-content;
                display: inline-flex;
                align-items: center;
                gap: 8px;
                &-bullet {
                    @include setSize(50px,5px);
                    @include radius(0);
                    margin: 0 !important;
                    &-active {
                        background-color: $colorMain;
                    }
                }
            }
        }

        // 卡片內容
        .card{
            &-body{
                padding: 0;
            }

            &-title{
                font-weight: 400;
                line-height: 1.15;
                word-wrap: normal;
            }

            &-img-top{
                text-align: center;
                img{
                    display: inline-block;
                }
            }
        }

        .swiper{
            &-pagination{
                bottom: get-vw(10px) !important;

                @include setStyleMinRwd(760px){
                    &-bullet {
                        opacity: 0.2;
                        &-active{
                            opacity: 1;
                        }
                    }
                }

                @include setMaxRwd{
                    justify-content: center; 
                }
            }
        }

        // 首頁產品分類 swiper
        &-pro{
            width: 100%;
            padding-top: get-vw(15px);
            padding-bottom: get-vw(40px);
            .card{
                &-body{
                    @include setSize(100%,100%);

                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @include setMinRwd{
                        position: absolute;
                        padding: 3em;
                        color: #fff;
                        background-color: rgba(49, 49, 49,0.85);
                    }
                }

                &-title{
                    @include fontSize(42px);
                    font-family: var(--fancy-slider-title-font-family);
                    border-bottom: $colorBase 1px solid;
                    text-align: center;
                    padding-bottom: 0.3em;
                    margin-bottom: 0.35em;

                    @include setMinRwd{
                        border-bottom-color: rgba(255,255,255,0.65); 
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                    }
                }

                @include setMinRwd{
                    &-linkNormal{
                        & ~ .card-body{
                            opacity: 0;
                            transition: var(--card-transition-duration);
                            .card-title,
                            .card-des{
                                transition-duration: var(--card-transition-duration);
                                transition-delay: var(--card-transition-delay);
                            }
    
                            .card-title{
                                transform: translateY(-1000%);
                            }
    
                            .card-des{
                                transform: translateY(1000%);
                            }
                        }
    
                        &:hover{
                            & ~ .card-body{
                                opacity: 1;
                                .card-title,
                                .card-des{
                                    transform: translateY(0);
                                }
                            }
                        }
                    }
                }

                @include pad{
                    flex-direction: row;
                    align-items: flex-start;
                    background-color: transparent;
                    
                    &-img-top{
                        @include radius(20px);
                        overflow: hidden;
                        flex-basis: 150px;
                        margin-top: 46px;
                    }

                    &-body{
                        padding-left: 1.25em;
                        padding-right: 0.25em;
                        flex: 0 0 calc(100% - 150px);
                    }

                    &-title{
                        @include fontSize(25px);
                    }
                }
            }

            .swiper{
                &-slide{
                    .card{
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                    
                    @include setMinRwd{
                        &-active,
                        &-next{
                            .card{
                                border-bottom-right-radius: 0 !important
                            }
                        }

                        &-active{
                            .card{
                                border-top-left-radius: 40%;
                            }
                        }

                        &-next{
                            .card{
                                @include radius(20px);
                            }
                        }

                        .card{
                            border-bottom-right-radius: 40%;
                            overflow: hidden;
                        }
                    }
                }

                &-act{
                    &-container{
                        @extend %setFlex;
                        align-items: flex-end;
                        justify-content: end;
                        margin-bottom: 2.75em;
                        padding-right: 1em;

                        .swiper-pagination {
                            position: relative;
                            width: auto;
                            // top: 8px;
                            top: 0px;
                            &::after{
                                content: '';
                                display: block;
                                @include setSize(100%,1px);
                                background-color: #cbcbcb;
                                position: absolute;
                                // top: 1px;
                                top: 25px;
                                z-index: -1;
                            }
                        }
                    }

                    &-button{
                        width: calc(3.5em + 10px);
                        padding-left: 10px;
                        @extend %setFlex-center;
                        justify-content: space-between;
                        .swiper-button-next,
                        .swiper-button-prev {
                            position: static;
                            color: $colorBase;
                            height: auto;
                            margin-top: 0;
                            &::after{
                                font-size: 14px;
                                @extend .fw-900
                            }
                        }
                    }
                }

                &-pagination{
                    &-fraction{
                        span{
                            color: #b9b9b9;
                            font-size: 15px;
                            font-family: $OpenSans-300;
                            padding: 0 2px;
                        }

                        .swiper-pagination-empty{
                            width: 30px;
                        }

                        .swiper-pagination-current{
                            position: relative;
                            color: $colorBase;
                            font-weight: 600;
                            &::after{
                                content: '';
                                display: block;
                                @include setSize(30px,3px);
                                background-color: $colorMain;
                                position: absolute;
                                bottom: -2px;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }
                }
            }

            @include setMaxRwd{
                display: flex;
                flex-direction: column;
                .swiper-act-container{
                    order: 1;
                    margin-top: 1.1em;
                }
            }
        }
    }

    // 內容
    &Grid{
        display: grid;
        grid-column-gap: 0;
        grid-auto-rows: 0fr 0fr 0fr;
        &-list{
            &-frAuto{
                grid-template-columns: repeat(1, auto) 1fr
            }
            
            &-1to1{
                grid-template-columns: 1fr 1fr;
            }

            &-2to1{
                grid-template-columns: 2fr 1fr 1fr;
            }
            
            &-avg{
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            &-1to2{
                grid-template-columns: 1fr 1fr 2fr;
            }
        }

        @include pad1080{
            grid-template-columns: auto !important;
        }
    }

    &Flex{
        @extend %setFlex;
    }
}

/* swiper index banner */
.swiper-bannerIn{
	width: 100%;
	height: 100%;
    overflow: hidden;

        .swiper-container {
            width: 100%;
            height: 100%;
        }
        
        .swiper-image {
            height: 100%;
        }

        .swiper-image-inner {
            background-size: cover;
            background-position: center center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-blend-mode: overlay;
        }

        .swiper-slide{
            transition: all 1s linear;
            transition-delay: 0.5s;
            opacity: 0;
            overflow: hidden;
        }

        .swiper-slide.swiper-slide-next {
            opacity: 0;
        }

        .swiper-slide,
        .swiper-slide.swiper-slide-active + .swiper-slide-next,
        .swiper-slide.swiper-slide-duplicate-active{
            transform: translateX(100%) translateZ(0);
        }
        
        .swiper-slide.swiper-slide-duplicate,
        .swiper-slide.swiper-slide-duplicate-next,
        .swiper-slide.swiper-slide-duplicate-prev{
            transform: translateX(-25%) translateZ(0);
        }

      
        .swiper-slide.swiper-slide-next,
        .swiper-slide.swiper-slide-prev{
            opacity: 0.9;
            transform: translateX(25%) translateZ(0);

            @include setWidthRwd(1100px,1500px){
                transform: translateX(20%) translateZ(0);
            }
        }

        .swiper-slide.swiper-slide-next .swiper-image,
        .swiper-slide.swiper-slide-prev .swiper-image{
            border-top-right-radius: 25em;
            overflow: hidden;
            &::after{
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                background-color: rgba(0, 0, 0, 0.65);
                z-index: 1;
            }

            @include setMaxRwd{
                border-top-right-radius: 4.5em;
            }
        }

        .swiper-slide.swiper-slide-active{
            opacity: 1;
            width: 100% !important;
            max-width: 1550px !important;
            transform: translateX(24%) translateZ(0);
            overflow: visible;

            @include setWidthRwd(1100px,1500px){
                transform: translateX(15%) translateZ(0);
            }

            @include setMaxRwd{
                transform: translateX(20%) translateZ(0);
            }
        }

        .swiper-slide.swiper-slide-active .swiper-image{
            border-top-left-radius: 25em;
            overflow: hidden;

            @include setMaxRwd{
                border-top-left-radius: 4.5em;
            }
        }

        .swiper-text{
            position: absolute;
            left: -12%;
            bottom: 10%;

            @include setWidthRwd(1100px,1500px){
                left: -10%;
            }

            @include setMaxRwd{
                bottom: 0;
            }
        }
        
        .swiper-image.swiper-image-active{
            img{
                width: 100%;
                height: 730px;
                object-fit: cover;

                @include setWidthRwd(1100px,1650px){
                    height: 40em;
                }
            }
        }

        .swiper-image-inner.swiper-image-active {
            transition: all 1s linear;
            transition-delay: 1s;
            background-blend-mode: multiply;
        }

        h1 {
            align-self: flex-start;

            color: #fff;
            font-family: $Poppins;
            font-weight: 600;
            font-size: get-vw-mobile(35px);
            line-height: var(--fancy-slider-title-line-height);
            font-family: var(--fancy-slider-title-font-family);
            letter-spacing: -1px;
            text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);

            transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1) 2s;
            transform: translate3d(-20%, 0, 0);
            opacity: 0;
            
            @include setMinRwd{
                font-size: get-vw(58px);
            }

            @include setMaxRwd{
                color: rgba(255,255,255,1) !important;
            }
        }

        h1 + p {
            font-family: $Poppins;
            letter-spacing: 1px;
            font-size: get-vw-mobile(16px);
            margin-top: 0.5em;
            color: #fff;
            @include setMinRwd{
                font-size: get-vw(17px);
                margin-top: 15px;
            }

            transition: all .8s cubic-bezier(0.215, 0.61, 0.355, 1) 2.3s;
            transform: translate3d(-20%, 0, 0);
            opacity: 0;
        }
        
        .swiper-slide.swiper-slide-active h1,
        .swiper-slide.swiper-slide-active h1 + p {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }

        /* Arrows */
        .swiper-button-prev,
        .swiper-button-next {
            width: 140px;
            transition: var(--fancy-slider-transition-button);
            outline: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            cursor: pointer;

            &::after{
                display: none;
            }

            @include setMaxRwd{
                width: 100px;
            }
        
            svg {
                display: block;
                transition: var(--fancy-slider-transition-button);
            }

            .fancy-slider-svg-circle-wrap {
                transition: var(--fancy-slider-transition-button-circle);
                transform-origin: -20px 40px;
                opacity: 1;
            }
        
            circle {
                transition: var(--fancy-slider-transition-button-circle);
                stroke-width: 2px;
                stroke: #fff;
                fill: none;
                stroke-dasharray: 1;
                stroke-dashoffset: 1;
                opacity: 1;
                transform-origin: 0px 0px 0px;
            }
        
            .fancy-slider-svg-arrow {
                transition: var(--fancy-slider-transition-button-arrow);
                fill: #fff;
                transform: rotateY(180deg) translate(-49px, 31.1px) scale(3)
            }
        
            .fancy-slider-svg-wrap {
                transform: translateY(353px);
            }
        
            .fancy-slider-svg-line {
                transition: var(--fancy-slider-transition-button);
                stroke: #fff;
                stroke-width: 2;
                transform: translate(50px, 42px);
            }
        
            &:not(.fancy-slider-button-disabled) {
                &:hover {
                    circle {
                        stroke-dasharray: 4px;
                        stroke-dashoffset: 4px;
                        opacity: 1;
                    }
                }
            }
        }

        .swiper-button-next{
            .fancy-slider-svg-wrap {
                transform: translateY(353px) rotateY(180deg);
		        transform-origin: 80px 0px 0px;
            }
        }

        .swiper-pagination{
            width: 100%;
            position: absolute;
            text-align: center;
            transition: 300ms;
            transform: translate3d(0, 0, 0);
            z-index: 10;
            &-bullet {
                width: 14px;
                height: 14px;
                background: #fff;
                opacity: 0.4;
                &-active {
                    opacity: 1;
                    background: #fff;
                }
            }
        }
      
}
