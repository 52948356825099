@mixin foot-flexSB{
  display: flex;
  justify-content: space-between;
}

footer {
  padding: 1em;
  background-color: #010101;

  &,a{
    color: #fff;
  }

  .footer {
    &-wrapper{
      @include setMinRwd{
        @include foot-flexSB;
      }
    }
    
    &-info{
      p{
        margin-bottom: 10px;
      }

      .copyright{
        @include fontSize(15px);
      }

      &-backTop{
        a{
          @include fontSize(16px);
          color: $colorItem;
        }

        span{
          padding-right: 5px;
        }

        @include setMaxRwd{
          display: none;
        }
      }

      &-left{
        .item{
          @include setMinRwd{
            @include foot-flexSB;
          
            p{
               & ~ p{
                padding-left: 20px;
               }
            }
          }
        }

        a{
          color: $colorItem;
        }

        @include setMaxRwd{
          p{
            margin-bottom: 0.875rem;
          }
        }
      }

      &-right{
        .iware{
          padding-left: 0 !important;
          padding-right: 0;
        }

        @include setMinRwd{ 
          text-align: right
        }

        @include setMaxRwd{
          margin-top: 15px;
          p{
            font-size: 13px !important;
          }
        }
      }
    }
  }

  @include setMinRwd{
    padding: 45px 25px 40px;
  }

  @include setMaxRwd{
    padding-bottom: 4em;
  }
}

// 給予區塊的左右側線條使用
.divider {
  width: 2px;
  height: 100%;
  background: #bfb1a5;
  @include setWidthRwd(800px,1000px){
    display: none;
  }
}

.iware {
  font-size: 87%;
  font-weight: 100;
  font-weight: lighter;
  margin-bottom: 0 !important;
  padding: 4px 8px;
  &, a{
    color: #bfbfbf;
  }
  
  a {
      &:hover {
          text-decoration: underline;
      }
  }

  b{
    font-weight: 100;
  }
}

// Gotop 使用
.float-link {
  right: -100%;
  position: fixed;
  bottom: 200px;
  z-index: 10;
  transition: 0.3s;
  .link-btn {
    a {
      &:hover {
          opacity: 0.65;
      }
    }

    &.nabar-icon-mobile{
      @include setMinRwd{
        display: none;
      }
      img{
        max-width: 100%;
      }
    }

    &.top{
      @include setMaxRwd{
        display: none;
      }
    }
  }

  &.active {
      right: 15px;
  }

  @include setStyleRwd(1600px){
    &.active {
        right: 10px;
    }
    
  }

  @include setMaxRwd{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    right: 0 !important;
    background: $colorItem;
    padding: 5px;
    margin: 0;
    .link-btn {
      text-align: center;
      padding: 6px;

      &,
      .link-btn-toggle{
        display: block;
        width: 100%;
      }

      .link-btn-toggle{
        color: #fff;
      }

      .icon{
        margin-left: 0;
        small{
          font-size: 100%;
          padding-right: 5px;
        }
      }
    }
  }
}